<template>
    <v-container fluid class="fill-height" dark >
        <v-row>
            <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" class="pa-0">
                <v-row>
                    <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">

                    </v-col>
                    <v-col  cols="12" class="d-flex align-center justify-center">
                        <v-img class="flex-grow-0" alt="Top Up Your Personal Gym logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>

                    </v-col>
                    <v-col cols="12">
                        <h2 class="display-1 white--text d-inline-block fw-1 no-select text-center full-width mb-3" >L'<span class="fw-5">APP</span> PER ALLENARTI <span class="fw-5">CON NOI DOVE VUOI</span></h2>
                        <p class="display-1 white--text text-center full-width d-inline-block fw-1 no-select mb-0" >con l'esclusivo</p>
                        <v-img class="d-block" max-height="60" contain src="../assets/logo-homekit-white-alpha.png"></v-img>
                        <p class="display-1 white--text text-center full-width d-inline-block fw-1 no-select" >in regalo al primo acquisto di video lezioni</p>
                    </v-col>
                    <v-col cols="12">
                        <v-divider dark />
                    </v-col>

                    <v-col cols="12" class="d-flex justify-center">
                        <h2 class="display-1 white--text d-inline-flex text-center align-center justify-center fw-1 no-select accent--text" >GRAZIE PER IL TUO INTERESSAMENTO!</h2>
                    </v-col>
                    <v-col cols="12">
                        <p class="display-1 white--text text-center d-inline-block full-width fw-1 no-select" v-if="!done">Saremo online prossimamente.</p>
                        <p class="display-1 white--text text-center d-inline-block full-width fw-1 no-select mb-0" v-if="!done">Se vuoi essere ricontattato appena la app sarà pronta puoi segnarti qui</p>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-icon color="accent" size="40">mdi-arrow-down-thick</v-icon>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center" v-if="!done">

                        <v-text-field dark v-model="email" label="Email" rounded filled style="max-width: 400px"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center"  v-if="!done">
                        <v-btn dark :loading="loading" :disabled="!validEmail" rounded color="accent" class="flex-shrink-1 flex-grow-0" @click="registerMailInList()">Segnami!</v-btn>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center mt-3" v-if="done">
                        <p class="display-1 accent--text text-center d-inline-flex align-center justify-center fw-1 no-select" >Grazie per esserti segnato! Ti ricontatteremo presto.</p>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    import utils from '../utils'
    import {mapActions} from 'vuex'
    export default {
        name: "InDevelopment",
        data() {
            return {
                email : '',
                done : false,
                loading : false
            };
        },
        computed: {
            validEmail() {
                return utils.isValidEmail(this.email)
            }
        },
        methods: {
            ...mapActions(['get']),
            registerMailInList() {
                this.loading = true;
                this.get({collection:'mailing', id : this.email}).then(() => {
                    this.loading = false;
                    this.done = true;
                });
            }
        },
        mounted() {
        }
    };
</script>
