<template>
  <v-container>
    <v-row v-if="item">
      <v-col cols="12" lg="6" xl="4">
        <v-img :aspect-ratio="16/9" :src="item.kit.cover.url" style="border-radius: 24px"></v-img>
      </v-col>
      <v-col cols="12" lg="6" xl="8">
        <h2 class="no-select display-1 white--text fw-1 text-uppercase mb-4">{{item.title.it}}</h2>

        <v-row>
          <v-col cols="12">
            <v-divider dark class="mb-3"/>
          </v-col>
          <v-col cols="12">
            <p class="no-select subtitle-2 text-uppercase white--text">tipologia</p>
            <p class="no-select body-1 white--text">{{item.kit.title.it}}</p>
          </v-col>
          <v-col cols="12">
            <p class="no-select subtitle-2 text-uppercase white--text">descrizione</p>
            <p class="no-select body-1 white--text">{{item.description.it}}</p>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="12">
        <v-divider  dark/>
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 d-flex justify-end">
        <v-btn class="flex-shrink-0 flex-grow-1 flex-md-grow-0 flex-md-shrink-1" rounded color="accent" @click="addProductToCart()" >Acquista!</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="addedToCartDialog" persistent max-width="500" class="secondary" dark>
      <v-card>
        <v-card-title class="title fw-1 text-uppercase accent--text">AGGIUNTO AL CARRELLO!</v-card-title>
        <v-card-text>
          <p class="body-1">Hai inserito questo pacchetto nel tuo carrello, vuoi finalizzare l'acquisto?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="$router.replace('/')">Cerca altro</v-btn>
          <v-btn color="accent" text @click="$router.replace('/cart')">Vai al carello</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'
import config from "../config";
import utils from "../utils";
export default {
  name: "PAcchetto",
  data () {
    return {
      addedToCartDialog : false,
      item : null,
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','purchases','cart','products']),
    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    isInCart() {
      for(let i = 0; i < this.cart.items.length; i++) {
        if(this.cart.items[i] === this.item._id) return true;
      }
      return false;
    },
  },
  methods : {
    ...mapActions(['load', 'get', 'sendError', 'refreshCollection', 'requestConfirm','notFound','addToCart','saveUserCart']),
    addProductToCart() {
      if(this.isInCart) {
        this.$router.push("/cart");
        return
      }

      let addProductToCart = () => {
        this.addToCart({ product: _.cloneDeep(this.item)});
        this.saveUserCart();
        this.addedToCartDialog = true;
      };

      console.log(this.cart.items)

      let filter = this.cart.items.filter(p => {
        let prod = utils.getObjectInArray(this.products, '_id', p);
        return prod && prod.kit._id === this.item.kit._id;
      });
      if(filter.length) {
        this.requestConfirm({title: 'Attenzione', message:'Hai già un pacchetto di tipo "' + this.item.kit.title.it + '" nel carrello, vuoi sostituirlo', callback:(ok) => {
          if(ok) addProductToCart();
          }
        });
        return;
      }
      addProductToCart();
    },
    async loadData() {
      let ok = true;
      try {
        let reply = await this.get({ collection : 'products', id: this.$route.params.id +'?display=true'})
        this.item = reply.data;
        if(!this.item) ok = false;
      } catch(err) {
        if(config.debug) console.log(err)
        ok = false;
      }
      this.loading = false;
      if(!ok) {
        this.notFound();
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.refreshCollection({collection:'products'})
    await this.loadData();
  }
}
</script>
