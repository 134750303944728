<template>
  <v-container fluid class="fill-height" dark >
    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0" >
        <v-form @submit="onSubmit">
          <v-row>
            <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
              <v-img class="flex-grow-0" alt="Top Up Your Personal Gym Logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" >ACCEDI</h2>
            </v-col>
            <v-col cols="12" class="mt-6">
              <v-text-field dark v-model="loginEmail" label="Email" rounded filled type="email"
                            :error="emailError"
                            :error-messages="requiredErrorMessages(emailError)"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field dark v-model="loginPassword" label="Password" type="password" rounded filled
                            :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPwd = !showPwd"
                            :error="passwordError"
                            :error-messages="requiredErrorMessages(passwordError)"></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn dark color="accent" type="submit" rounded >Accedi</v-btn>
            </v-col>
            <v-col cols="12" class="text-center ">
              <span class="body-1 white--text">oppure</span>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn dark color="accent" rounded @click="onRegisterClick">Registrati</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12" class="text-center" >
            <v-btn text color="accent" class="underline tolower" @click="lostPasswordClick">password dimenticata?</v-btn>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

    <v-dialog v-model="lostPasswordDialog" max-width="500" dark>
      <v-card >
        <v-card-title class="headline fw-1 text-uppercase accent--text">RESET PASSWORD</v-card-title>
        <v-card-text>
          <p class="body-1">Hai dimenticato la password? Inserisci qui la mail del tuo account e ti invieremo una nuova password per accedere.</p>
          <v-text-field dark v-model="loginEmail" label="Email" rounded filled type="email" hide-details></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="lostPasswordDialog = false">Annulla</v-btn>
          <v-btn color="accent" text @click="recoverPassword()">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import config from "../config";
export default {
  name: "Login",
  data() {
    return {
      showPwd : false,
      loginEmail : null,
      loginPassword : null,
      lostPasswordDialog : false,
      passwordError: false,
      emailError: false,
    };
  },
  computed: {
    ...mapGetters(["token", "profile"]),
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
  },
  methods: {
    ...mapActions([
      "sendSuccess",
      "sendError",
      "requestConfirm",
      'setToken',
      'setProfile',
      "logout",
      "login",
      "alert", 'insert', 'resetPassword'
    ]),
    requiredErrorMessages(err) {
      if (err) return ["Riempi questo campo!"];
      return [];
    },
    onSubmit(e) {
      e.preventDefault();
      this.onLoginClick();
    },
    onLoginClick() {
      this.emailError = !this.loginEmail
      this.passwordError = !this.loginPassword

      if(this.emailError || this.passwordError) return;

      this.loading = true;
      this.login({ email : this.loginEmail, password : this.loginPassword}).then((reply) => {
        if(config.debug) console.log(reply);
        this.setToken({ token : reply.token });
        this.setProfile({ profile : reply.user });

        setTimeout(() => {
          this.loading = false;
          this.$router.replace("/")
        }, 1000);
      }).catch((err) => {
        this.alert( { message : (err.message ? err.message : "errore sconosciuto") } );
        this.loading = false;
      });
    },
    onRegisterClick() {
      this.$router.replace("/register")
    },
    lostPasswordClick() {
      this.lostPasswordDialog = true
    },
    async recoverPassword() {
      try {
        await this.resetPassword({email:this.loginEmail})
      } catch (e) {
        console.log(e)
      }
      this.alert({message:'Se la mail inserita è corretta, controlla la tua casella di posta, ti è stata inviata una mail con una nuova password. Potrai cambiare la tua password dalla sezione account'})
      this.lostPasswordDialog = false
    }
  },
  mounted() {
  }
};
</script>
