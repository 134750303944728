<template>
  <v-container class="mt-6">
    <v-row>
      <v-col cols="12"><h2 class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">{{page.title.it}}</h2></v-col>
      <v-col cols="12"><v-divider dark></v-divider></v-col>
      <v-col cols="12"><p class="title white--text full-width fw-3 text-justify no-select">{{page.content.it}}</p></v-col>
      <v-col cols="12" v-if="page.video" class="d-flex justify-center"><video :src="page.video.url" controls class="full-width" style="object-fit: contain; height: auto; max-width: 960px; max-height: 540px"></video></v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "CmsPage",
  data () {
    return {
      page : {
        title : { it : '' },
        content : { it : '' },
        video : null,
      }
    };
  },
  computed : {
    ...mapGetters(['cms'])

  },
  methods : {
    ...mapActions(['refreshCollection'])
  },
  mounted() {
    setTimeout(()=>{
      let filter = this.cms.filter((item)=>{
        return item.path === "/"+this.$route.params.path
      })
      if(filter.length) {
        this.page = filter[0];
      } else {
        this.$router.go(-1);
      }
    }, 1000)
  }
}
</script>

<style scoped>

</style>
