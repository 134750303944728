<template>
    <v-container fluid class="fill-height" dark >
        <v-row>
            <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" class="pa-0">
                <v-row>
                    <v-col cols="12">
                        <h2 class="display-1 accent--text d-inline-block fw-1 no-select text-center full-width mb-3 " >CHI SIAMO</h2>
                    </v-col>
                    <v-col cols="12">
                        <v-divider dark />
                    </v-col>

                    <v-col cols="12">
                        <p class="white--text text-justify no-select">La TopGym è una palestra nata il 20 luglio 2016, basata su una dinamica di Personal Trainer.</p>
                        <p class="white--text text-justify no-select">La nostra sede operativa si trova in Piazzale Kennedy n. 1/B – 06012, Città di Castello (PG).</p>
                        <p class="white--text text-justify no-select">All’interno della TopGym lavorano solo professionisti Laureati in Scienze Motorie, con le più disparate specializzazioni che il ramo sportivo propone.</p>
                        <p class="white--text text-justify no-select">Alleniamo le persone in maniera diretta.</p>
                        <p class="white--text text-justify no-select">Non esistono schede di allenamento, ma siamo sempre Noi a dire il da farsi ad ogni seduta, a menochè non ci siano degli obiettivi particolari quali ipertrofia ad esempio, che richiedono tali schede.</p>
                    </v-col>
                    <v-col cols="12">
                        <v-img width="100%" contain src="../assets/interno1.jpg"></v-img>
                    </v-col>
                    <v-col>
                        <p class="white--text text-justify no-select">Dopo l’arrivo della pandemia da Covid-19 in Italia nel febbraio/marzo 2020 e conseguente lockdown nazionale, abbiamo pensato ed ideato questa applicazione, in modo tale da permettere alle persone di allenarsi da casa con oggetti ed esercizi professionali, ma indipendente da cosa il futuro ci riserverà, essa è stata pensata per essere funzionante ed attiva sempre e comunque, quindi anche per tutte quelle persone che preferiscono allenarsi da casa, o vogliono portarsi la palestra con se in vacanza ad esempio.</p>
                    </v-col>
                    <v-col cols="12">

                        <p class="white--text text-justify no-select">Top Up inoltre permette di rimanere connessi costantemente con noi, garantendo un percorso da fare assieme.</p>
                        <p class="white--text text-justify no-select">Il kit che si ottiene in regalo all’acquisto del primo pacchetto è estremamente unico nel suo genere e contiene tutti gli oggetti nuovi che serviranno per allenarsi.</p>
                        <p class="white--text text-justify no-select">Cercheremo, grazie al vostro aiuto e ai vostri feedback, di migliorarla e svilupparla sempre al meglio.</p>
                    </v-col>
                    <v-col cols="6">
                        <v-img width="100%" contain src="../assets/gabriele_polchi.jpg"></v-img>
                    </v-col>
                    <v-col cols="6">
                        <v-img width="100%" contain src="../assets/stefano_scudero.jpg"></v-img>
                    </v-col>
                    <v-col cols="12">
                        <p class="accent--text text-justify no-select font-italic">Gabriele Polchi e Stefano Scudero</p>
                    </v-col>

                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "ChiSiamo",
        data() {
            return {
                loading : false
            };
        },
        methods: {

        },
        mounted() {
        }
    };
</script>
