import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeClassic from '../pages/HomeUp.vue'
import HomeAlternate from '../pages/HomeUpAlternate.vue'
import HomeRoot from '../pages/HomeRoot.vue'

import Login from "../pages/Login";
import Register from "../pages/Register";
import NotFound from "../pages/NotFound";
import RegisterConfirm from "../pages/RegisterConfirm";
import Kit from "../pages/Kit";
import InDevelopment from "../pages/InDevelopment";
import Account from "../pages/Account";
import Trainings from "../pages/AllProducts";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Paid from "../pages/Paid";
import MyTrainings from "../pages/MyTrainings";
import Training from "../pages/Training";
import Lesson from "../pages/Lesson";
import LessonAlt from "../pages/LessonAlternate";
import Workout from "../pages/Workout";
import Report from "../pages/Report";
import ChiSiamo from "../pages/ChiSiamo";
import CosaSiamo from "../pages/CosaSiamo";
import ErrorPage from "../pages/Error";

import config from "../config";
import Intro from "../pages/Intro";
import CmsPage from "../pages/CmsPage";
import Seats from "../pages/Seats";
import Pacchetto from "../pages/Pacchetto";
import Gallery from "../pages/Gallery";
import Medical from "../pages/Medical.vue";

const HomeUp = config.homeAlternate ? HomeAlternate : HomeClassic;

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeRoot
  },
  {
    path: '/topup',
    name: 'home up',
    component: HomeUp
  },
  {
    path: '/pages/:path',
    name: 'cms page',
    component: CmsPage
  },
  {
    path: '/intro',
    name: 'intro',
    component: Intro
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/paid',
    name: 'paid',
    component: Paid
  },
  {
    path: '/mytrainings',
    name: 'my trainings',
    component: MyTrainings
  },
  {
    path: '/trainings/:id',
    name: 'training',
    component: Training
  },
  {
    path: '/trainings/:purchase_id/:id',
    name: 'lesson',
    component: Lesson
  },
  {
    path: '/trainings2/:purchase_id/:id',
    name: 'lesson alt',
    component: LessonAlt
  },
  {
    path: '/trainings/:purchase_id/:id/work',
    name: 'work',
    component: Workout
  },
  {
    path: '/report/:purchase_id/:id',
    name: 'report',
    component: Report
  },
  {
    path: '/products',
    name: 'products',
    component: Trainings
  },
  {
    path: '/confirm/:token',
    name: 'register confirm',
    component: RegisterConfirm
  },
  {
    path: '/development',
    name: 'development',
    component: InDevelopment
  },
  {
    path: '/notfound',
    name: 'not found',
    component: NotFound
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '/products/:id',
    name: 'kit',
    component: Kit
  },
  {
    path: '/gym/:id',
    name: 'pacchetto',
    component: Pacchetto
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ChiSiamo
  },
  {
    path: '/whatwedo',
    name: 'whatwedo',
    component: CosaSiamo
  },
  {
    path: '/seats',
    name: 'seats',
    component: Seats
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/medical',
    name: 'medical',
    component: Medical
  },
  {   path: '/index.html',
    component: HomeRoot,
    alias: '/'
  },
  {
    path: '*',
    name: 'not found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
