import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import CircularCountDownTimer from "vue-circular-count-down-timer";
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './style/main.css'


Vue.config.productionTip = false

Vue.use(CircularCountDownTimer);

router.afterEach((to, from) => {
  console.log('nextBg')
  store.dispatch('nextBg')

})

store.dispatch('router', { router })



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
