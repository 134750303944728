<template>
  <v-container fluid class="fill-height" dark >
    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0">
        <v-form @submit="onSubmit">
          <v-row>
            <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
              <v-img class="flex-grow-0" alt="Top Up Your Personal Gym Logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" v-if="step <= 3">REGISTRATI</h2>
              <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" v-else>GRAZIE PER ESSERTI REGISTRATO! </h2>
            </v-col>
            <v-col cols="12" v-if="step > 3">
              <p class="white--text no-select text-center" >Ora il prossimo passo è controllare la tua casella di posta: ti abbiamo inviato una mail per confermare il tuo account. Se non riesci a trovarla nella tua posta "in arrivo" prova a cercare tra la posta "indesiderata".</p>
            </v-col>
            <v-col cols="12" v-if="step > 3" class="d-flex justify-center">
              <v-btn rounded color="accent" class="flex-grow-0 flex-shrink-1"  @click="$router.replace('/')">vai alla home</v-btn>
            </v-col>
            <v-stepper v-model="step" vertical dark style="background: transparent" class="full-width elevation-0"  v-if="step <= 3">
              <template >
                <template v-for="n in steps">
                  <v-stepper-step :key="`${n}-step`" :complete="step > n" :step="n" editable>
                    {{stepName(n)}}
                  </v-stepper-step>

                  <v-stepper-content :key="`${n}-content`" :step="n">
                    <v-row>
                      <v-col cols="12"  v-if="n===1">
                        <v-text-field
                                label="Nome*"
                                rounded filled
                                v-model="editingItem.name"
                                @input="nameError = false"
                                :error="nameError"
                                :error-messages="requiredErrorMessages(nameError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===1">
                        <v-text-field
                                label="Cognome*"
                                rounded filled
                                v-model="editingItem.surname"
                                @input="surnameError = false"
                                :error="surnameError"
                                :error-messages="requiredErrorMessages(surnameError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===1">
                        <v-text-field
                                label="Telefono"
                                rounded filled
                                v-model="editingItem.phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===1">
                        <v-text-field
                                label="Indirizzo residenza*"
                                rounded filled
                                v-model="editingItem.address.name"
                                :error="addressError"
                                @input="addressError = false"
                                :error-messages="requiredErrorMessages(addressError)"
                                hint="Via\Piazza ..."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="8"  v-if="n===1">
                        <v-text-field
                                label="Città*"
                                rounded filled
                                v-model="editingItem.address.city"
                                @input="cityError = false"
                                :error="cityError"
                                :error-messages="requiredErrorMessages(cityError)"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="n===1">
                        <v-text-field
                                label="Prov.*"
                                rounded filled
                                v-model="editingItem.address.province"
                                @input="provError = false"
                                :error="provError"
                                :error-messages="requiredErrorMessages(provError)"
                                hint="es. PG"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===1">
                        <v-text-field
                                label="CAP*"
                                rounded filled
                                v-model="zipCode"
                                :error="capError"
                                @input="capError = false"
                                :error-messages="requiredErrorMessages(capError)"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="n===1 && editingItem.user_type === 'card'">

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                    label="Codice fiscale*"
                                    rounded solo
                                    v-model="editingItem.fiscal_code"
                                    :rules="[rules.fiscalCode]"
                                    @input="fiscalCodeUppercaser"
                                    append-icon="mdi-help-circle"
                                    :error="fiscalError"
                                    :error-messages="requiredErrorMessages(fiscalError)"
                                    v-on="on"
                            ></v-text-field>
                          </template>
                          <span class="no-select">Abbiamo bisogno del tuo codice fiscale perché sei residente in Umbria e, dato che la nostra palestra è associata MSP italia, abbiamo l'obbligo di tesserarti (se non lo sei già), secondo le normative vigenti nazionali. Il costo della tessera è di {{coniCardPrice}} euro e ti verrà addebbitato solo nel caso in cui non sei già tesserato, o la tua tessera è scaduta, al momento del tuo primo acquisto.</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="12" v-if="n===1 && editingItem.user_type === 'card'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                    label="Luogo di nascita*"
                                    rounded solo
                                    v-model="editingItem.city_of_birth"
                                    append-icon="mdi-help-circle"
                                    v-on="on"
                            ></v-text-field>
                          </template>
                          <span class="no-select">Abbiamo bisogno della tuo luogo di nascita perché sei residente in Umbria e, dato che la nostra palestra è associata MSP italia, abbiamo l'obbligo di tesserarti (se non lo sei già), secondo le normative vigenti nazionali. Il costo della tessera è di {{coniCardPrice}} euro e ti verrà addebbitato solo nel caso in cui non sei già tesserato, o la tua tessera è scaduta, al momento del tuo primo acquisto.</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="12" v-if="n===1 && editingItem.user_type === 'card'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                    label="Provincia di nascita*"
                                    rounded solo
                                    v-model="editingItem.province_of_birth"
                                    append-icon="mdi-help-circle"
                                    v-on="on"
                            ></v-text-field>
                          </template>
                          <span class="no-select">Abbiamo bisogno della tuo luogo di nascita perché sei residente in Umbria e, dato che la nostra palestra è associata MSP italia, abbiamo l'obbligo di tesserarti (se non lo sei già), secondo le normative vigenti nazionali. Il costo della tessera è di {{coniCardPrice}} euro e ti verrà addebbitato solo nel caso in cui non sei già tesserato, o la tua tessera è scaduta, al momento del tuo primo acquisto.</span>
                        </v-tooltip>
                      </v-col>


                      <!-- STEP 2 --->

                      <v-col cols="12" v-if="n===2">

                        <v-tooltip top color="accent">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                    label="Data di nascita*"
                                    rounded filled
                                    type="date"
                                    v-model="dateOfBirth"
                                   append-icon="mdi-help-circle"
                                    v-on="on"
                                    persistent-hint
                                    :hint="age + ' anni'"
                                    @input="birthError = false"
                                    :error="birthError"
                                    :error-messages="requiredErrorMessages(birthError)"
                            ></v-text-field>
                          </template>
                          <span class="no-select">La tua data di nascita ci serve per il calcolo automatico dell'età e della FC max teorica</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="12" v-if="n===2">
                        <v-text-field
                                label="Altezza (cm)*"
                                rounded filled
                                type="number"
                                :step="1"
                                v-model="editingItem.height"
                                @input="heightError = false"
                                :error="heightError"
                                :error-messages="requiredErrorMessages(heightError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===2">
                        <v-text-field
                                label="Peso (kg)*"
                                rounded filled
                                type="number"
                                :step="1"
                                v-model="editingItem.weight"
                                hide-details
                                @input="weightError = false"
                                :error="weightError"
                                :error-messages="requiredErrorMessages(weightError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===2">
                        <p class="subtitle-1 accent--text no-select"><span class="font-weight-bold">FC max teorica:</span> {{fcMax}} Bpm</p>
                        <p class="subtitle-1 accent--text no-select"><span class="font-weight-bold">Indice di massa corporea (BMI):</span> {{bmi}}</p>
                      </v-col>
                      <v-col cols="12" v-if="n===2">
                        <v-select
                                dark
                                label="Qta di allenamento medio"
                                rounded filled
                                persistent-hint
                                hint="a settimana"
                                :items="trainingOptions"
                                v-model="editingItem.training"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" v-if="n===3">
                        <v-text-field dark v-model="editingItem.email" label="Email*" rounded filled
                                      :error="emailError"
                                      :error-messages="requiredErrorMessages(emailError)"></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===3">
                        <v-text-field dark v-model="editingItem.password" label="Password*" rounded filled
                                      :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="showPwd = !showPwd"
                                      :error="passwordError" :error-messages="passwordErrorMessages(passwordError)"></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===3">
                        <v-text-field dark v-model="editingItem.confirm_password" label="Conferma password*"  rounded filled
                                      :type="showConfPwd ? 'text' : 'password'" :append-icon="showConfPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="showConfPwd = !showConfPwd"
                                      :error="confirmPasswordError"
                                      :error-messages="confirmPasswordErrorMessages(confirmPasswordError)"></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===3 && editingItem.user_type === 'card'" class="white--text">
                        <v-checkbox v-model="editingItem.certificate" label="Ho già fornito o mi impegno a fornire TopGym personal studio S.s.d.r.l. di certificato medico sportivo idoneo e, successivamente, a reinviarne uno nuovo ad ogni scadenza di certificato, previo utilizzo degli allenamenti acquistati su questa piattaforma, come da normativa vigente. Mi assumo altresì ogni responsabilità nel caso in cui non rispetti tale normativa.*"></v-checkbox>
                        <p>La consegna del certificato può essere fatta per posta o a mano in sede operativa presso TopGym personal studio S.s.d.r.l. Piazzale Kennedy n. 1/B, Città di Castello (Pg), oppure per posta elettronica all'indirizzo <a href="mailto:topgymstudio@gmail.com">topgymstudio@gmail.com</a>.</p>
                      </v-col>
                      <v-col cols="12" v-if="n===3" class="white--text privacy-text">
                        Registrandoti accetti e dichiari di aver preso visione delle <a @click="contractDialog = true">condizioni generali di vendita e termini d'uso</a> e dell'<a @click="privacyDialog = true">informativa sul trattamento dei dati personali</a> ai sensi degli art. 13 e 14 del Regolamento UE 679/2016 e del D.lgs. 196/2003 e successive modifiche, ed inoltre:
                        <v-checkbox class="privacy-check" v-model="editingItem.privacy_1" label="Acconsenti all'utilizzo dei tuoi dati per attività di marketing come elaborare studi, ricerche, statistiche di mercato volti a migliorare il servizio oppure inviarti materiale promozionale inerente alle attività, ai servizi e ai prodotti di TopGym (es. sconti, novità, ecc.). "></v-checkbox>
                        <v-checkbox class="privacy-check" v-model="editingItem.privacy_2"  label="Acconsenti alla comunicazione dei tuoi dati a partner commerciali di Top Up Your Personal Gym per finalità di marketing, ossia per inviarti materiale promozionale inerente ad attività strettamente connesse alle attività, ai servizi e ai prodotti di TopGym. (es. sconti, omaggi, ecc.)"></v-checkbox>
                        <v-checkbox class="privacy-check" v-model="editingItem.privacy_3"  label="Acconsenti, ove applicabile, alla profilazione, ossia all'analisi dei tuoi interessi, abitudini e scelte di consumo, al fine di poterti inviare materiale promozionale personalizzato sui servizi offerti da TopGym"></v-checkbox>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-end">
                        <span class="body-2 white--text no-select">* campi richiesti</span>
                      </v-col>
                      <v-col cols="12"  class="d-flex justify-end">
                        <v-btn class="ml-3" color="accent" rounded @click="goBack()">Indietro</v-btn>
                        <v-btn class="ml-3" color="accent" rounded @click="goNext()" v-if="step < 3">Continua</v-btn>
                        <v-btn class="ml-3" color="accent" type="submit" rounded v-if="step === 3" >Registrati</v-btn>
                      </v-col>
                    </v-row>

                  </v-stepper-content>
                </template>
              </template>
            </v-stepper>
          </v-row>
        </v-form>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import utils from "../utils";
import config from "../config";
import moment from 'moment'
export default {
  name: "Register",
  data() {
    return {
      showPwd : false,
      showConfPwd : false,
      step : 1,
      steps : 3,
      editingItem: {
        email: "",
        name: "",
        surname: "",
        role: "user",
        phone: "",
        fiscal_code: "",
        address: {
          name: "",
          city: "",
          province: "",
          zip_code: ""
        },
        city_of_birth: "",
        province_of_birth: "",
        date_of_birth: new Date(),
        height : 0,
        weight : 0,
        training : 1,
        register_date: new Date(),
        password: "",
        user_type: "commercial",
        confirm_password: "",
        active: true,
        certificate: false,
        privacy_1 : true,
        privacy_2 : true,
        privacy_3 : true,
      },
      trainingOptions : [
        { text : "Meno di 1 giorno", value : 0},
        { text : "1 giorno", value : 1},
        { text : "2 giorni", value : 2},
        { text : "3 giorni", value : 3},
        { text : "4 giorni", value : 4},
        { text : "5 giorni", value : 5},
        { text : "6 giorni", value : 6},
        { text : "7 giorni", value : 7},
      ],
      showFiscalCodeExplain: false,
      rules: {
        fiscalCode: value => {
          if(utils.isValidCF(value)) {
            return true;
          }
          return "Codice fiscale non valido";
        }
      },
      nameError: false,
      surnameError: false,
      addressError: false,
      cityError: false,
      provError: false,
      capError: false,
      fiscalError: false,
      passwordError: false,
      confirmPasswordError: false,
      emailError: false,
      birthError: false,
      heightError: false,
      weightError: false,
    };
  },
  computed: {
    ...mapGetters(["token", "profile",'settings']),
    coniCardPrice() {
      return this.settings.coni_card_price.toFixed(2)
    },
    privacyDialog : {
      set(newVal) {  this.$store.state.privacyDialog = newVal },
      get() { return this.$store.state.privacyDialog }
    },
    contractDialog : {
      set(newVal) {  this.$store.state.contractDialog = newVal },
      get() { return this.$store.state.contractDialog }
    },
    age() {
      const age = moment().diff(moment(this.editingItem.date_of_birth), 'years');
      if(_.isNaN(age)) return 0;
      return Math.max(age, 0)
    },
    fcMax() {
      const age = moment(this.editingItem.date_of_birth).diff(moment(), 'years');
      if(_.isNaN(age)) return 220;
      return 220 - Math.abs(age);
    },
    bmi() {
      const weight = utils.toInt( this.editingItem.weight, 1 )
      const height = utils.toInt( this.editingItem.height, 100 ) / 100;
      const bmi = (weight / ( height * height ))
      if(_.isNaN(bmi)) return '-'
      return bmi.toFixed(2);
    },
    dateOfBirth: {
      get() {
        return moment(this.editingItem.date_of_birth).format("YYYY-MM-DD");
      },
      set(newVal) {
        this.editingItem.date_of_birth = moment(newVal, "YYYY-MM-DD").toDate();
      }
    },
    zipCode : {
      get() {
        return this.editingItem.address.zip_code
      },
      set( value ) {
        this.editingItem.address.zip_code = value;

        if(value[0] !== '0') {
          this.editingItem.user_type = 'commercial';
          return
        }

        let cap = parseInt(value, 10);

        if (_.isNaN(cap)) {
          this.editingItem.user_type = 'commercial';
          return
        }

        if(cap >= 5010 && cap <= 5100) {
          //terni
          this.editingItem.user_type = 'card';
          return
        }
        if(cap >= 6010 && cap <= 6089) {
          //perugia
          this.editingItem.user_type = 'card';
          return
        }
        if(cap >= 6121 && cap <= 6135) {
          //perugia 2
          this.editingItem.user_type = 'card';
          return
        }
        this.editingItem.user_type = 'commercial';
      }
    },
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
  },
  methods: {
    ...mapActions([
      "sendSuccess",
      "sendError",
      "requestConfirm",
      'setToken',
      'setProfile',
      "logout",
      "register", "alert"
    ]),
    stepName( n ) {
      switch ( n ) {
        case 1:
          return "Informazioni contatto"
        case 2:
          return "Stato di forma"
        case 3:
          return "Account"
      }
    },
    fiscalCodeUppercaser() {
      if(this.editingItem.fiscal_code) {
        this.editingItem.fiscal_code = this.editingItem.fiscal_code.toUpperCase();
      }
    },
    requiredErrorMessages(err) {
      if (err) return ["Riempi questo campo!"];
      return [];
    },
    passwordErrorMessages(err) {
      if (err) return ["Inserisci una password di almeno 8 caratteri!"];
      return [];
    },
    confirmPasswordErrorMessages(err) {
      if (err) return ["La password e la conferma password devono essere uguali!"];
      return [];
    },
    onSubmit(e) {
      e.preventDefault();
      this.onRegisterClick();
    },
    onRegisterClick() {
      const hasError = this.accountStep();
      if(hasError) return


      this.loading = true;
      this.register({ body : this.editingItem }).then(()=>{
        this.loading = false;
        this.step = 4;
      }).catch((err)=>{
        this.loading = false;
        this.alert({title:"Oops! c'è stato un errore", message: err.message}).then(()=>{});
      })
    },
    goBack() {
      if(this.step === 1) {
        this.$router.replace('/login')
      } else {
        this.step--;
      }
    },
    goNext() {

      switch (this.step) {
        case 1:
          this.infoStep();
          break
        case 2:
          this.fitnessStep();
          break
      }
    },
    infoStep() {
      this.nameError = !this.editingItem.name;
      this.surnameError = !this.editingItem.surname;
      this.addressError = !this.editingItem.address.name
      this.cityError = !this.editingItem.address.city
      this.provError = !this.editingItem.address.province
      this.capError = !this.editingItem.address.zip_code
      this.fiscalError = false
      if(this.editingItem.user_type === 'card') {
        if(!this.editingItem.fiscal_code || !utils.isValidCF(this.editingItem.fiscal_code)) {
          this.fiscalError = true;
          return
        }
      }
      if(this.nameError || this.surnameError || this.addressError || this.cityError || this.provError || this.capError) {
        return;
      }
      setTimeout(()=>{
        this.step++
      }, 500)

    },
    fitnessStep() {
      this.editingItem.weight = utils.toInt(this.editingItem.weight, 0)
      this.editingItem.height = utils.toInt(this.editingItem.height, 0)

      this.birthError = this.editingItem.age <= 0;
      this.weightError = this.editingItem.weight <= 0;
      this.heightError = this.editingItem.height <= 0;

      if(this.birthError || this.weightError || this.heightError) return

      setTimeout(()=>{
        this.step++
      }, 500)
    },
    accountStep() {
      this.editingItem.email = this.editingItem.email ? this.editingItem.email.toLowerCase() : '';
      this.emailError = !utils.isValidEmail(this.editingItem.email);
      this.passwordError = !utils.isValidPassword(this.editingItem.password);
      this.confirmPasswordError = this.editingItem.password !== this.editingItem.confirm_password;
      return this.emailError || this.passwordError || this.confirmPasswordError
    }
  },
  mounted() {
  }
};
</script>
