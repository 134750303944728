const buildHeader = (token) => {
    if(!token) return null
    return { 'x-token' : token }
};

const doRequest = (method, url, body, extraHeaders)=>{
    //console.log(method, url);
    return new Promise(function (resolve, reject) {
        let request = new XMLHttpRequest();
        request.open(method, url);
        let noContentType = true;
        if(extraHeaders) {
            for(let k in extraHeaders) {
                if(k.toLowerCase() === 'content-type') {
                    noContentType = false;
                }
                request.setRequestHeader(k, extraHeaders[k]);
            }
        }
        if(noContentType && body) {
            request.setRequestHeader('Content-Type', 'application/json');
        }
        request.addEventListener("load", ( e ) => {
            const status = e.target.status;
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = {}
            }

            if(typeof reply === 'string') {
                reply = {
                    message : reply
                }
            }

            reply.statusCode = status;

            if(status === 200) {
                resolve(reply);
                return;
            }
            reject(reply);
        }, false);
        request.addEventListener("error",( e ) => {
            const status = e.target.status;
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = { status : "fail", message : "errore sconosciuto"}
            }
            reply.statusCode = status;
            reject( reply );
        }, false);
        if(body) {
            request.send(JSON.stringify(body));
        } else{
            request.send();
        }
    })
};

const doFormRequest = (method, url, body, extraHeaders)=>{
    //console.log(method, url);
    return new Promise(function (resolve, reject) {
        let request = new XMLHttpRequest();
        request.open(method, url);
        if(extraHeaders) {
            for(let k in extraHeaders) {
                request.setRequestHeader(k, extraHeaders[k]);
            }
        }
        request.addEventListener("load", ( e ) => {
            const status = e.target.status;
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = { error : e };
                console.log(e)
            }

            if(typeof reply === 'string') {
                reply = {
                    message : reply
                }
            }

            if(status === 200) {
                resolve(reply);
                return;
            }
            reply.status =  e.target.status;
            reject( reply );
        }, false);
        request.addEventListener("error",( e ) => {
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = { error : e };
                console.log(e)
            }
            reply.status =  e.target.status;
            reject( reply );
        }, false);
        if(body) {
            request.send(body);
        } else{
            request.send();
        }
    })
};
import config from '../config'

const index = {
    login : (email, password) => {
        let url = config.server + "/login";
        return doRequest("POST", url, { email, password });
    },
    register : ( body ) => {
        let url = config.server + "/register";
        return doRequest("POST", url, body);
    },
    confirmRegister : ( token ) => {
        let url = config.server + "/confirm/" + token;
        return doRequest("GET", url, null);
    },
    heartbeat : (token) => {
        let url = config.server + "/heartbeat";
        return doRequest("GET", url, null, buildHeader(token));
    },
    profile : (token) => {
        let url = config.server + "/profile";
        return doRequest("GET", url, null, buildHeader(token));
    },
    profileUpdate : (token, body) => {
        let url = config.server + "/profile";
        return doRequest("PUT", url, body, buildHeader(token));
    },
    restLoad : ( token, collection, query, limit, page, sort, order, noauth ) => {
        let url = config.server + "/api/"+collection+"?limit="+limit+"&page="+(page-1);
        for(const k in query) {
            if(query[k])
                url += "&"+k+"="+query[k];
        }
        if(sort) {
            url += "&sort="+sort;
            url += "&order=" + (order === "DESC" ? "DESC" : "ASC");
        }


        if(noauth) {
            return doRequest("GET", url, null, null);
        }

        return doRequest("GET", url, null, buildHeader(token));
    },
    post : ( path, data, token ) => {
        let url = config.server + "/" + path;
        return doRequest("POST", url, data, buildHeader(token));
    },
    restGet : ( token, collection, id ) => {
        let url = id ? config.server + "/api/"+ collection + "/" + id :  config.server + "/api/"+ collection;
        return doRequest("GET", url, null, buildHeader(token));
    },
    restInsert : ( token, collection, data ) => {
        let url = config.server + "/api/" + collection;
        return doRequest("POST", url, data, buildHeader(token));
    },
    restUpdate : ( token, collection, data ) => {
        let url = config.server + "/api/"+collection+"/" + data._id;
        return doRequest("PUT", url, data, buildHeader(token));
    },
    restDelete : ( token, collection, id ) => {
        let url = config.server + "/api/"+collection+"/" + id;
        return doRequest("DELETE", url, null, buildHeader(token));
    },
    upload : (token, where, formData) => {
        let url = config.server + "/api/" + where;
        return doFormRequest("POST", url, formData, buildHeader(token))
    },
    isReachable() {
        return fetch(config.server, { method: 'HEAD', mode: 'no-cors' })
            .then(function(resp) {
                return resp && (resp.ok || resp.type === 'opaque');
            })
            .catch(function(err) {
                console.warn('[conn test failure]:', err);
            });
    }


};
export default index;