<template>
  <v-container fluid class="fill-height" dark >
    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" class="pa-0">
        <v-row>
          <v-col cols="12">
            <h2 class="display-1 accent--text d-inline-block fw-1 no-select text-center full-width mb-3 " >GALLERY</h2>
          </v-col>
          <v-col cols="12">
            <v-divider dark />
          </v-col>
          <v-col cols="6" md="4" xl="3" v-for="(item, i) in gallery" :key="item._id">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" class="pointer" @click="openItem(i)" >
                <v-img :src="item.icon" :aspect-ratio="1" :alt="item.title" :title="item.title" v-ripple />
              </v-card>
            </v-hover>

          </v-col>

        </v-row>
      </v-col>

    </v-row>
    <v-dialog v-model="galleryDialog" >
      <v-card color="black" dark flat tile >
        <v-window v-model="onboarding"  >
          <v-window-item v-for="(item, i) in gallery" :key="`gall_${item._id}`">
            <v-img :src="item.src" :alt="item.title" :title="item.title" height="80vh" contain/>
          </v-window-item>
        </v-window>
        <v-card-actions class="justify-space-between">
          <v-btn text @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group v-model="onboarding" class="text-center" mandatory>
            <v-item v-for="(item, i) in gallery" :key="`btn-${i}`" v-slot="{ active, toggle }">
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn text @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>

        <v-btn text absolute top right @click="galleryDialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card>


    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Gallery",
  data() {
    return {
      galleryDialog : false,
      loading : false,
      onboarding : 0
    }
  },
  computed : {
    ...mapGetters(['gallery']),

  },
  methods : {
    ...mapActions(['refreshCollectionPromise']),
    openItem(i) {
      this.onboarding = i;
      this.galleryDialog = true;
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0
          ? this.gallery.length - 1
          : this.onboarding - 1
    },
    next() {

      this.onboarding = this.onboarding + 1 === this.gallery.length
          ? 0
          : this.onboarding + 1
    },
  },
  async mounted() {

  }
}
</script>

<style scoped>

</style>