<template>
  <v-container fluid class="fill-height" dark >
    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0">
        <v-row>
          <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
            <v-img class="flex-grow-0" alt="Top Up Logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" >PRENOTA UNA VISITA MEDICA</h2>
          </v-col>
          <v-col cols="12" v-if="booked" class="text-center">
            <h4 class="white--text font-weight-medium text-center mb-3">LA TUA PRENOTAZIONE</h4>
            <p class="white--text font-weight-light text-center">Hai prenotato una visita medica per il {{formatDateTime(booked.when)}}</p>
            <v-btn color="primary" rounded @click="cancBook()">ANNULLA PRENOTAZIONE</v-btn>
          </v-col>
          <v-col cols="12" v-else class="text-center">
            <h4 class="white--text font-weight-medium text-center">DATE DISPONIBILI</h4>
            <v-radio-group v-model="selected" @change="selectedTime = null">
              <v-radio
                  class="white elevation-2 pa-2 rounded"
                  v-for="opt in medicalhours"
                  :key="opt._id"
                  :label="medicalhourLabel(opt)"
                  :value="opt._id"
              ></v-radio>
            </v-radio-group>
            <h4 class="white--text font-weight-medium text-center mb-3" v-if="selected">SCEGLI L'ORARIO</h4>
            <v-select label="Orario" class="mb-2"  rounded filled dark  v-if="selected" v-model="selectedTime" :items="timeOptions"></v-select>
            <v-btn color="primary" rounded :disabled="!selected || !selectedTime" @click="bookDate()">PRENOTA</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateTime} from "luxon";
import utils from "../utils";

export default {
  name: "Medical",
  data() {
    return {
      selected : null,
      selectedTime : null,
    }
  },
  computed : {
    ...mapGetters(['profile','medicalhours','medicalbooking']),
    booked() {
      if(!this.profile) return false
      const filter = this.medicalbooking.filter(b => {
        const dt = DateTime.fromISO(b.when)
        return b.user_id === this.profile._id && dt > DateTime.now()
      })
      return filter.length ? filter[0] : null
    },
    timeOptions() {
      if(!this.selected) {
        return []
      }

      const options = []

      const item = utils.getObjectInArray(this.medicalhours, '_id', this.selected)

      let dt = DateTime.fromISO(item.from)
      const end = DateTime.fromISO(item.to)
      while (dt < end) {
        if(this.isAvailable(dt)) {
          options.push({ text : dt.toFormat("HH:mm"), value : dt.toISO()})
        }
        dt = dt.plus({minute:10})
      }
      return options
    }
  },
  methods : {
    ...mapActions(['refreshCollection','insert','remove','sendSuccess','sendError','requestConfirm']),
    formatDateTime( date ){
      const dt = DateTime.fromISO(date)
      return dt.toFormat("dd/MM/yyyy' alle 'HH:mm")
    },
    medicalhourLabel( opt ) {
      const from = DateTime.fromISO(opt.from)
      const to = DateTime.fromISO(opt.to)
      return `${from.toFormat("dd/MM/yyyy")} dalle ${from.toFormat("HH:mm")} alle ${to.toFormat("HH:mm")}`
    },
    isAvailable(dt) {
      for(let i = 0; i < this.medicalbooking.length; i++) {
        const test = DateTime.fromISO(this.medicalbooking[i].when)
        if(test.toFormat("dd/MM/yyyy HH:mm") === dt.toFormat("dd/MM/yyyy HH:mm")) {
          return false
        }
      }
      return true
    },
    cancBook() {
      this.requestConfirm({title:"Richiesta conferma", message: `Sei sicuro di voler annullare la tua prenotazione per la visita medica del ${this.formatDateTime(this.booked.when)}`,
        callback : ok =>{
          if(ok) {
            this.loading = true
            this.remove({collection:'medicalbooking', id: this.booked._id}).then(res => {
              this.loading = false
              this.sendSuccess({message : 'Prenotazione annullata'})
              this.refreshCollection({collection:'medicalbooking'})
              this.selected = null
              this.selectedTime = null
            }).catch(err => {
              this.loading = false
              const message = err.message || "Qualcosa è andato storto. Riprova più tardi"
              this.sendError({message})
            })
          }
        }
      })
    },
    bookDate() {
      this.requestConfirm({
        title: "Richiesta conferma",
        message: `Sei sicuro di voler prenotare una visita medica il ${this.formatDateTime(this.selectedTime)}`,
        callback: ok => {
          if (ok) {
            const data = {
              user_id : this.profile._id,
              when : this.selectedTime
            }
            this.loading = true
            this.insert({collection:'medicalbooking', data}).then(res => {
              this.loading = false
              this.sendSuccess({message : 'Prenotazione effettuata'})
              this.refreshCollection({collection:'medicalbooking'})
              this.selected = null
              this.selectedTime = null

            }).catch(err => {
              this.loading = false
              const message = err.message || "Qualcosa è andato storto. Riprova più tardi"
              this.sendError({message})
            })
          }
        }
      });

    }
  },
  mounted() {
    this.refreshCollection({collection:'medicalhours'})
    this.refreshCollection({collection:'medicalbooking'})
  }
}
</script>