<template>
    <v-container class="grid-list-md" >
        <v-row>
            <v-col cols="12">
                <h1 class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">TUTTI GLI ALLENAMENTI</h1>
            </v-col>
        </v-row>
        <v-divider dark class="mb-4"/>

        <v-row>
            <v-expansion-panels inset flat hover dark >
                <v-expansion-panel dark class="secondary" active-class="filter-open">
                    <v-expansion-panel-header class="headline fw-1 text-uppercase accent--text d-flex flex-wrap">
                        <span class="d-inline-flex flex-grow-0 flex-shrink-1 ml-3">Filtri attivi</span>
                        <v-chip v-if="searchName" close class="filter-preview d-inline-flex flex-grow-0 flex-shrink-1 ml-3 mt-1 mb-1" @click:close="searchName = ''; applyFilter()">Nome: "{{searchName}}"</v-chip>
                        <v-chip v-if="searchCtg" close class="filter-preview d-inline-flex flex-grow-0 flex-shrink-1 ml-3 mt-1 mb-1" @click:close="searchCtg = null; applyFilter()">Categoria: {{ctgName(searchCtg)}}</v-chip>
                        <v-chip v-if="searchGenre" close class="filter-preview d-inline-flex flex-grow-0 flex-shrink-1 ml-3 mt-1 mb-1" @click:close="searchGenre = null; applyFilter()">Genere: {{genreName(searchGenre)}}</v-chip>
                        <v-chip v-if="searchDiff" close class="filter-preview d-inline-flex flex-grow-0 flex-shrink-1 ml-3 mt-1 mb-1" @click:close="searchDiff = null; applyFilter()">Difficoltà: {{diffName(searchDiff)}}</v-chip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" md="6" lg="4" xl="3">
                                <v-text-field
                                        label="Cerca per nome"
                                        rounded filled
                                        v-model="searchName"
                                        @input="applyFilter()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded filled v-model="searchCtg" label="Categoria" @input="applyFilter" :items="categoryOptions" clearable></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded filled v-model="searchGenre" label="Genere" @input="applyFilter" :items="genreOptions" clearable></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded filled v-model="searchDiff" label="Difficoltà" @input="applyFilter" :items="difficultyOptions" clearable></v-select>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider dark class="mb-4 mt-4"/>
        <v-progress-linear indeterminate v-if="loading" color="accent" rounded></v-progress-linear>
        <v-row>
            <v-col cols="12" :sm="kits.length > 1 ? 6 : 12" :lg="kits.length > 2 ? 4 : 6" :xl="kits.length > 3 ? 3 : 4" v-for="item in kits" :key="item._id">
                <v-card @click="onItemSelect(item)">
                    <v-img :aspect-ratio="16/9" :src="item.cover.url">
                        <v-chip class="no-select d-inline-flex text-uppercase fw-6 ma-3" :color="difficultyColor(item.difficulty)">{{item.difficulty.name.it}}</v-chip>
                    </v-img>
                    <v-card-text>
                        <h3 class="no-select title">{{item.title.it}}</h3>
                        <p class="no-select subtitle-1 mb-0 accent--text font-weight-bold text-uppercase">{{item.category.name.it}}</p>
                        <p class="no-select subtitle-1 mb-0">{{minPrice(item)}} €</p>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

    </v-container>

</template>

<script>
    import {mapGetters, mapActions, mapState } from 'vuex'
    import config from "../config";
    export default {
        name: "AllProducts",
        data () {
            return {
                loading : false,
                searchName : '',
                searchCtg : null,
                searchGenre : null,
                searchDiff : null,
                kits : [],
                query : {}
            }
        },
        computed : {
            ...mapState(['bgs']),
            ...mapGetters(['token', 'profile','bgImage','products', 'purchases','ongoing','categories','genre','difficulties']),
            loggedIn() {
                return this.profile != null
            },
            categoryOptions() {
                return this.categories.map((item)=>{
                    return { text : item.name.it, value: item._id }
                })
            },
            genreOptions() {
                return this.genre.map((item)=>{
                    return { text : item.name.it, value: item._id }
                })
            },
            difficultyOptions() {
                return this.difficulties.map((item)=>{
                    return { text : item.name.it, value: item._id }
                })
            }
        },
        methods: {
            ...mapActions(['logout', 'load', 'sendError','refreshCollection','requestConfirm','onScroll']),
            genreName(id) {
                for(let i = 0; i < this.genreOptions.length; i++) {
                    if(id === this.genreOptions[i].value) return this.genreOptions[i].text;
                }
                return '...'
            },
            ctgName(id) {
                for(let i = 0; i < this.categoryOptions.length; i++) {
                    if(id === this.categoryOptions[i].value) return this.categoryOptions[i].text;
                }
                return '...'
            },
            diffName(id) {
                for(let i = 0; i < this.difficultyOptions.length; i++) {
                    if(id === this.difficultyOptions[i].value) return this.difficultyOptions[i].text;
                }
                return '...'
            },
            applyFilter() {
                this.query.title = this.searchName ? this.searchName : undefined;
                this.query.genre = this.searchGenre ? this.searchGenre : undefined;
                this.query.ctg = this.searchCtg ? this.searchCtg : undefined;
                this.query.diff = this.searchDiff ? this.searchDiff : undefined;
                this.saveQuery()
                this.refresh()
            },
            saveQuery() {
               localStorage.trainingsSearch = JSON.stringify(this.query);
            },
            difficultyColor(difficulty) {
                switch (difficulty.value) {
                    case 1:
                        return 'success'
                    case 2:
                        return 'lime'
                    case 3:
                        return 'warning'
                    case 4:
                        return 'error'
                }
                return 'white'
            },
            onItemSelect(item) {
                this.$router.push('/products/' + item._id);
            },
            minPrice( item ) {
                let prods = this.products.filter((p)=>{
                    return p.kit._id === item._id;
                });
                if(!prods.length) return 0
                let price;
                for(let i = 0; i < prods.length; i++) {
                    if(i === 0) {
                        price = prods[i].price;
                        continue;
                    }
                    price = Math.min(price, prods[i].price);
                }
                return price;
            },
            getKit(id) {
                for(let i = 0; i < this.kits.length; i++) {
                    if(this.kits[i]._id === id) return this.kits[i];
                }
                return null
            },
            getProduct(id) {
                for(let i = 0; i < this.products.length; i++) {
                    if(this.products[i]._id === id) return this.products[i];
                }
                return null
            },

            refresh() {
                this.loading = true;
                let filter = { query : this.query, limit : 0, page : 0, sort : null, order: null, noauth: true }
                this.load({collection:'kits', filter}).then((reply)=>{
                    this.loading = false;
                    this.kits = reply.data;
                }).catch((err) =>{
                    this.sendError({message: "Oops! C'è stato un errore! ricarica la pagina"});
                    console.log(err)
                });
            }
        },
        mounted() {
            if(localStorage.trainingsSearch) {
                this.query = JSON.parse(localStorage.trainingsSearch);
                this.searchName = this.query.title || '';
                this.searchCtg = this.query.ctg || '';
                this.searchDiff = this.query.diff || '';
                this.searchGenre = this.query.genre || '';
            }


            this.refreshCollection({collection: 'products'});
            this.refreshCollection({collection: 'categories'});
            this.refreshCollection({collection: 'genre'});
            this.refreshCollection({collection: 'difficulties'});
            this.refresh();
        }
    }
</script>

<style scoped>

</style>