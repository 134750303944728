<template>
    <v-container fluid class="fill-height" dark >
        <v-row>
            <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" class="pa-0">
                <v-row>
                    <v-col cols="12">
                        <h2 class="display-1 accent--text d-inline-block fw-1 no-select text-center full-width mb-3 text-uppercase" >Cos'è Top Up Your Personal Gym</h2>
                    </v-col>
                    <v-col cols="12">
                        <v-divider dark />
                    </v-col>
                    <v-col cols="12">
                        <p class="white--text text-justify no-select">Top Up è una web application esclusiva targata Top Gym Personal Studio, ideata per l'allenamento a casa o dove si vuole.</p>
                        <p class="white--text text-justify no-select">Contiene al suo interno un database video di esercizi descritti vocalmente e dettagliatamente per il loro corretto svolgimento.</p>
                        <p class="white--text text-justify no-select">Non una semplice raccolta di video esercizi, ma una suddivisione in pacchetti mirati per categorie e livelli di difficoltà e intensità d'allenamento. Dopo essersi registrati, ed aver inserito tutti i parametri fisici personali richiesti, l'utente potrà scegliere il pacchetto di video lezioni da svolgere del livello di difficoltà scelto.</p>
                        <p class="white--text text-justify no-select">Le nostre voci guida ti seguiranno come un vero Personal Trainer nell'esecuzione degli esercizi, dettando i tempi di lavoro e i recuperi.</p>
                    </v-col>
                    <v-col cols="12">
                        <v-img width="100%" contain src="../assets/interno2.jpg"></v-img>
                    </v-col>
                    <v-col cols="12">
                        <p class="white--text text-justify no-select">La metodologia di allenamento che qui proponiamo è la stessa di quella che utilizziamo nella nostra palestra Top Gym Personal Studio di Città di Castello (PG), un approccio basato sull'allenamento ad alta intensità partendo dal facile fino ad arrivare ad esercizi combinati di livello avanzato.</p>
                        <p class="white--text text-justify no-select">Dopo un periodo di allenamento l'utente potrà rapportarsi con i nostri Personal Trainer compilando un report che consentirà, grazie ai nostri consigli, di poter proseguire al meglio gli allenamenti.</p>
                        <p class="white--text text-justify no-select">La Top Gym è una società sportiva affiliata MSP Italia (ente di promozione sportiva CONI) e come tale, se sei residente in Umbria, richiede il tesseramento presso la propria società sportiva, secondo le vigenti norme nazionali. La quota di tesseramento sarà aggiunta automaticamente al tuo acquisto ed è valida per un anno, anche nel caso di iscrizione presso il centro Top Gym Personal Studio. Viceversa, se sei già un nostro tesserato, essa è valida anche qui.</p>
                    </v-col>
                    <v-col cols="6">
                        <v-img contain src="../assets/screen_1.jpg"></v-img>
                    </v-col>
                    <v-col cols="6">
                        <v-img contain src="../assets/screen_2.jpg"></v-img>
                    </v-col>

                    <v-col cols="12" class="mt-3">
                        <h2 class="display-1 accent--text d-inline-block fw-1 no-select text-center full-width mb-3 text-uppercase" >Cos'è l'Home Kit</h2>
                    </v-col>
                    <v-col cols="12">
                        <v-divider dark />
                    </v-col>
                    <v-col cols="12">
                        <p class="white--text text-justify no-select">L'Home Kit è un esclusivo prodotto, unico nel suo genere, che si potrà acquistare da solo o ricevere in omaggio col primo acquisto di video lezioni. Al suo interno contiene attrezzi diversificati per categoria Uomo o Donna, che sono indispensabili per lo svolgimento degli esercizi proposti nelle video lezioni.</p>
                        <p class="white--text text-justify no-select">Il kit è stato assemblato, testato e scelto dal team della Top Gym, ed è possibile acquistarlo anche singolarmente. Non sarà possibile svolgere le video lezioni senza il possesso del Kit.</p>
                    </v-col>
                    <v-col cols="12">
                        <v-img width="100%" contain src="../assets/home_kit.jpg"></v-img>
                    </v-col>
                    <v-col cols="12">
                        <p class="white--text text-justify no-select">Il ritiro del kit potrà avvenire o presso la Top Gym Personal Studio SSD in Piazzale Kennedy 1/b durante gli orari di apertura, oppure si potrà richiederne la spedizione presso il proprio domicilio con una piccola spesa in più.</p>
                    </v-col>

                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "CosaSiamo",
        data() {
            return {
                loading : false
            };
        },
        methods: {

        },
        mounted() {
        }
    };
</script>
