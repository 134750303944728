<template>
  <div class="full-width fill-height" style="background: #000" >

    <video ref="video" playsinline src="https://topupgym.s3.amazonaws.com/topupgym/static/spot.m4v" autoplay class="full-width fill-height"
           style="object-fit: contain; position: absolute"
           @play="isVideoPaused = false" @pause="isVideoPaused = true" ></video>
    <div class="d-flex full-width fill-height justify-center align-center" style="position: absolute; background: rgba(0,0,0,.4)" v-if="isVideoPaused" >
      <v-btn color="accent" icon width="150" height="150"  @click="playVideo()" >
        <v-icon size="150">mdi-play-circle-outline</v-icon>
      </v-btn>
    </div>

    <div style="position: absolute; right: 80px; top: 6px" >
      <v-checkbox dark color="accent" label="non mostrare più" v-model="alwaysSkip" @change="onAlwaysSkipChange()"></v-checkbox>
    </div>
    <v-btn fab color="accent" small top right fixed @click="$router.replace('/topup?i=done')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Intro",
  data () {
    return {
      isVideoPaused : true,
      alwaysSkip : false,
    };
  },
  computed : {
    ...mapGetters(['userInteracted']),
  },
  methods : {
    playVideo(){
      this.$refs.video.play();
    },
    onAlwaysSkipChange() {
      if(this.alwaysSkip) {
        console.log("skip")
        localStorage.introSkipped = true
      } else {
        console.log("no skip")
        localStorage.removeItem('introSkipped');
      }

    }
  },
  mounted() {
    if(localStorage.introSkipped) {
      this.alwaysSkip = true;
    }
  }
}
</script>

<style scoped>

</style>
