<template>
  <v-container class="grid-list-md" >
    <div class="d-flex justify-end">

      <v-btn v-for="page in cms" :key="page.path" text small color="accent" @click="$router.push('/pages' + page.path)">{{page.title.it}}</v-btn>
      <v-btn text small color="accent" @click="$router.push('/whatwedo')">Cos'è Top Up e HomeKit</v-btn>
      <v-btn text small color="accent" @click="$router.push('/contacts')">Chi siamo</v-btn>
    </div>
    <v-row>
      <v-col cols="12">
        <h1 v-if="loggedIn" class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">CIAO {{profile.name}}! COSA VUOI FARE OGGI?</h1>
        <h1 v-else class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">LA TUA PALESTRA IN UN KIT... BASTA SOLO UN CLICK!</h1>
      </v-col>
    </v-row>
    <v-divider dark class="mb-4"/>

    <v-row>
      <v-col cols="12">
        <!--
        <div v-if="loggedIn && isCardUser" v-ripple class="full-width section-title pointer dark-alpha pl-6 pr-6 pt-4 pb-4" style="border-bottom: thin solid rgba(255, 255, 255, 0.12)" @click="$router.push('/seats')">
          <h2 class="display-1 white--text no-select text-center text-lg-left text-uppercase fw-1" >Sedute outdoor</h2>
        </div>
        -->
        <v-expansion-panels dark >
          <v-expansion-panel class="dark-alpha" v-for="section in sections" :key="section._id">
            <v-expansion-panel-header class="section-title display-1 white--text no-select text-center text-lg-left text-uppercase fw-1">{{section.name.it}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" :sm="section.items.length > 1 ? 6 : 12" v-for="(item, i) in section.items" :key="section._id+'_'+i">
                  <v-card @click="onItemSelect(section, item)" v-if="section.type !== 'resume' && section.type !== 'mykits'">
                    <v-img :aspect-ratio="16/9" :src="item.cover.url">
                      <v-chip v-if="!isHomeKit(item)" class="no-select text-uppercase fw-6 ma-3" :color="difficultyColor(item.difficulty)">{{item.difficulty.name.it}}</v-chip>
                    </v-img>
                    <v-card-text>
                      <h3 class="no-select title">{{item.title.it}}</h3>
                      <p class="no-select subtitle-1 mb-0 accent--text font-weight-bold text-uppercase">{{item.category.name.it}}</p>
                      <p class="no-select subtitle-1 mb-0 text-uppercase fw-3 success--text" v-if="!isHomeKit(item) && item.difficulty.value === 1">per principianti</p>
                      <p class="no-select subtitle-1 mb-0 text-uppercase fw-3 lime--text" v-if="!isHomeKit(item) && item.difficulty.value === 2">per sportivi di base</p>
                      <p class="no-select subtitle-1 mb-0 text-uppercase fw-3 warning--text" v-if="!isHomeKit(item) && item.difficulty.value === 3">per sportivi evoluti</p>
                      <p class="no-select subtitle-1 mb-0 text-uppercase fw-3 error--text" v-if="!isHomeKit(item) && item.difficulty.value === 4">per sportivi top performance</p>
                      <p class="no-select subtitle-1 mb-0" v-if="!isHomeKit(item)">a partire da {{minPrice(item).toFixed(2)}}€</p>
                      <p class="no-select subtitle-1 mb-0" v-else>{{minPrice(item).toFixed(2)}}€ (iva inclusa)</p>
                    </v-card-text>
                  </v-card>

                  <v-card @click="onItemSelect(section, item)" v-else>
                    <v-img :aspect-ratio="16/9" :src="item.kit.cover.url">
                      <v-chip class="no-select text-uppercase fw-6 ma-3" :color="difficultyColor(item.kit.difficulty)">{{item.kit.difficulty.name.it}}</v-chip>
                    </v-img>
                    <v-card-text>
                      <div class="d-flex">
                        <h3 class="no-select title d-inline-flex">{{item.kit.title.it}} - {{item.product ? item.product.title.it : ""}}</h3>
                      </div>
                      <p class="no-select subtitle-1 mb-0 accent--text font-weight-bold text-uppercase">{{item.kit.category.name.it}}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="homeKitAlertDialog" persistent max-width="420" dark class="secondary">
      <v-card>
        <v-card-title class="headline fw-1 text-uppercase accent--text">ATTENZIONE!</v-card-title>
        <v-card-text>
          <p class="body-1">L'Home kit è già in omaggio all'acquisto del primo pacchetto, ma può essere acquistato anche separatamente.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="selectedHomeKit = null; homeKitAlertDialog = false;">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="onAlertDetail()">Scopri di più</v-btn>
          <v-btn color="accent" text @click="onAlertContinue()">Continua</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import config from "../config";
export default {
  name: "Home",
  data () {
    return {
      homeKitAlertDialog : false,
      sections : [],
      selectedHomeKit : null
    }
  },
  watch :{
    loggedIn() {
      //console.log("changed logged in")
      this.getHome();
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','products','kits','purchases','ongoingPurchases','usablePurchases','initialized','cms']),
    loggedIn() {
      return this.profile != null
    },
    isCardUser() {
      return this.profile && this.profile.user_type === 'card'
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
  },
  methods: {
    ...mapActions(['logout', 'load', 'sendError','refreshCollection','refreshCollectionPromise','requestConfirm','onScroll','loadPurchases']),
    difficultyColor(difficulty) {
      switch (difficulty.value) {
        case 1:
          return 'success'
        case 2:
          return 'lime'
        case 3:
          return 'warning'
        case 4:
          return 'error'
      }
      return 'white'
    },
    onItemSelect(section, item) {
      if(section.type === 'resume' || section.type === 'mykits') {
        this.$router.push('/trainings/' + item.purchase._id);
        return;
      }
      if(this.isHomeKit(item)) {
        this.selectedHomeKit = item;
        this.homeKitAlertDialog = true;
        return;
      }
      this.$router.push('/products/' + item._id);
    },

    minPrice( item ) {
      let prods = this.products.filter((p)=>{
        return p.kit._id === item._id;
      });
      if(!prods.length) return 0
      let price;
      for(let i = 0; i < prods.length; i++) {
        const prod = prods[i];
        let prodPrice = prod.price;
        if(prod.second_price && this.isPurchased(prod)) {
          prodPrice = prod.second_price;
        }
        if(i === 0) {
          price = prodPrice;
          continue;
        }
        price = Math.min(price, prodPrice);
      }
      if(this.isHomeKit(item)) {
        const iva = price / 100 * config.iva;
        price+=iva;
      }
      /*
      if(!this.profile || this.profile.user_type === 'commercial') {
        const iva = price / 100 * config.iva;
        price+=iva;
      }*/
      return price;
    },
    getKit(id) {
      for(let i = 0; i < this.kits.length; i++) {
        if(this.kits[i]._id === id) return this.kits[i];
      }
      return null
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    isPurchased(product) {
      let filter = this.purchases.filter((item)=> item.product._id === product._id)
      return filter.length > 0;
    },
    isHomeKit(item) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i].kit._id === item._id) {
          return this.products[i].sku === config.homeKitSkuF || this.products[i].sku === config.homeKitSkuM;
        }
      }
      return false;
    },
    onAlertDetail(){
      this.selectedHomeKit = null;
      this.homeKitAlertDialog = false;
      this.$router.push('/whatwedo');
    },
    onAlertContinue(){
      let id = this.selectedHomeKit._id;
      this.selectedHomeKit = null;
      this.homeKitAlertDialog = false;
      this.$router.push('/products/' + id);
    },
    buildHome( data ) {
      if(!data) {
        this.sections = []
        this.loading = false;
        this.sendError({message: "Oops! C'è stato un errore! ricarica la pagina"});
        return
      }

      if(!this.loggedIn) {
        data = data.filter((item)=>{
          return item.type !== 'resume' && item.type !== 'mykits'
        })
      }

      data = data.map((item) => {
        item.items = item.items.filter(i => !i.category.is_gym_category)
        if(item.items.length) {
          return item
        }
        switch (item.type) {
          case 'resume':
            item.items = [];
            for(let i = 0; i < this.ongoingPurchases.length; i++) {

              const purchase = this.ongoingPurchases[i];
              if(!purchase.product) continue;
              const kit = this.getKit(purchase.product.kit_id)
              if(!kit) continue
              item.items.push({ purchase, kit})
              if(item.max_items > 0 && item.max_items <= item.items.length) {
                break
              }
            }
            break
          case 'mykits':
            item.items = [];
            for(let i = 0; i < this.usablePurchases.length; i++) {
              const purchase = this.usablePurchases[i];
              if(!purchase.product) continue;
              const product = this.getProduct(purchase.product._id)
              if(product.sku === config.homeKitSkuF || product.sku === config.homeKitSkuM) {
                continue;
              }
              const kit = this.getKit(purchase.product.kit_id)
              if(!kit) continue
              item.items.push({
                purchase, product, kit
              });
              if(item.max_items > 0 && item.max_items <= item.items.length) {
                break
              }
            }
            break
          default:
            break;
        }
        return item;
      });


      data = data.filter((item)=>{
        return item.items.length > 0;
      })


      this.sections = data
      this.loading = false;
    },
    getHome() {
      this.loading = true;
      let filter = { query : {}, limit : 0, page : 0, sort : null, order: null, noauth: true }
      this.load({collection:'home', filter}).then((reply)=>{
        this.buildHome(reply.data)
      }).catch((err) =>{
        this.sendError({message: "Oops! C'è stato un errore! ricarica la pagina"});
        console.log(err)
      });
    },
    goAll(section) {
      switch (section.type) {
        case 'custom':
          localStorage.removeItem('trainingsSearch')
          this.$router.push('/products')
          return
        case 'genre':
          localStorage.trainingsSearch = JSON.stringify({
            genre : section.type_filter
          })
          this.$router.push('/products');
          return;
        case 'category':
          localStorage.trainingsSearch = JSON.stringify({
            ctg : section.type_filter
          })
          this.$router.push('/products')
          return
        case 'difficulty':
          localStorage.trainingsSearch = JSON.stringify({
            diff : section.type_filter
          })
          this.$router.push('/products')
          return
        case 'mykits':
          this.$router.push('/mytrainings')
          return
      }
    }
  },
  mounted() {
    this.$store.commit('paymentError', { err: null })
    if(this.$route.query.t === 'iamthedeveloper') {
      config.develomentPage = false;
    }
    if(config.develomentPage) {
      this.$router.replace("/development")
      return
    }

    this.loading = true;

    const catcher = (err) => {
      if(config.debug) {
        console.error(err)
      }
    };

    const loadHome = () => {
      let loop = setInterval(()=>{
        if(this.initialized) {
          clearInterval(loop);
          this.getHome()
        }
      }, 200)
    }

    this.refreshCollectionPromise({collection: 'kits'}).then(()=>{
      this.refreshCollectionPromise({collection: 'products'}).then(()=>{
        if(this.loggedIn) {
          this.loadPurchases().then(loadHome).catch(catcher)
        } else {
          loadHome();
        }

      }).catch(catcher);
    }).catch(catcher);

    if(!localStorage.introSkipped && this.$route.query.i !== 'done') {
      this.$router.replace('/intro');
    }
  }
}
</script>

<style scoped>

</style>