<template>
  <v-container class="grid-list-md" >
    <div class="d-flex justify-end">
      <v-btn text small color="accent" @click="$router.push('/gallery')" v-if="gallery.length">Gallery</v-btn>
      <v-btn text small color="accent" @click="$router.push('/contacts')">Chi siamo</v-btn>
    </div>
    <v-divider dark class="mb-4"/>

    <v-row>
      <v-col cols="12" class="pb-3" v-if="loggedIn && isCardUser && noMedicalCert" >
        <v-alert dense border="left" type="error">{{noMedicalCertMessage}}
          <v-btn text :small="$vuetify.breakpoint.smAndDown" @click="$router.push('/medical')">PRENOTA VISITA MEDICA</v-btn>
          <v-btn text :small="$vuetify.breakpoint.smAndDown" @click="$router.push('/account')">VEDI PROFILO</v-btn>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <div v-if="loggedIn && isCardUser" v-ripple class="full-width section-title pointer dark-alpha pl-6 pr-6 pt-4 pb-4" style="border-bottom: thin solid rgba(255, 255, 255, 0.12)" @click="$router.push('/seats')">
          <h2 class="display-1 white--text no-select text-left text-uppercase fw-1" >Prenota seduta in palestra</h2>
        </div>

        <v-expansion-panels dark >
          <v-expansion-panel class="dark-alpha" v-for="section in sections" :key="section._id">
            <v-expansion-panel-header class="section-title display-1 white--text no-select text-left text-uppercase fw-1">{{section.title.it}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="6" lg="4" v-for="(item, i) in section.items" :key="section._id+'_'+i">
                  <v-card @click="onItemSelect(section, item._id)">
                    <v-img :aspect-ratio="16/9" :src="item.cover"></v-img>
                    <v-card-text>
                      <h3 class="no-select title">{{item.name.it}}</h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-ripple class="full-width section-title pointer dark-alpha pl-6 pr-6 pt-4 pb-4" style="border-top: thin solid rgba(255, 255, 255, 0.12)" @click="$router.push('/topup')">
          <h2 class="display-1 white--text no-select text-left text-uppercase fw-1" >Home Fitness</h2>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import moment from "moment";
import utils from "../utils";
import {DateTime} from "luxon";
export default {
  name: "Home",
  data () {
    return {
      sections : [],
      selectedHomeKit : null
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','products','kits','purchases','ongoingPurchases','usablePurchases','initialized','cms','subcategories','gallery']),

    loggedIn() {
      return this.profile != null
    },
    isCardUser() {
      return this.profile && this.profile.user_type === 'card'
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    noMedicalCert() {
      if(!this.profile.medical_cert_expiration) return true
      const dt = DateTime.fromISO(this.profile.medical_cert_expiration)
      return dt.minus({day: 7}) < DateTime.now();
    },
    noMedicalCertMessage() {
      if(!this.profile.medical_cert_expiration) return "Non hai ancora un certificato medico sportivo valido!"
      const dt = DateTime.fromISO(this.profile.medical_cert_expiration)
      if(dt < DateTime.now()) {
        return `Il tuo certificato medico sportivo è scaduto il ${dt.toFormat("dd/MM/yyyy")}!`
      }
      if(dt.minus({day : 7}) < DateTime.now()) {
        return `Il tuo certificato medico sportivo è in scadenza il ${dt.toFormat("dd/MM/yyyy")}!`
      }
      return null
    }
  },
  methods: {
    ...mapActions(['logout', 'load', 'sendError','refreshCollection','refreshCollectionPromise','requestConfirm','onScroll','loadPurchases']),
    onItemSelect(item, sub_id) {
      //this.$router.push('/gym/' + item._id);
      this.$router.push('/products/' + item._id + '?sub='+sub_id);
    },
    getKit(id) {
      for(let i = 0; i < this.kits.length; i++) {
        if(this.kits[i]._id === id) return this.kits[i];
      }
      return null
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    isPurchased(product) {
      let filter = this.purchases.filter((item)=> item.product._id === product._id)
      return filter.length > 0;
    },

    onAlertDetail(){
      this.selectedHomeKit = null;
      this.homeKitAlertDialog = false;
      this.$router.push('/whatwedo');
    }
  },
  async mounted() {
    this.$store.commit('paymentError', { err: null })
    this.loading = true;
    await Promise.all([
      this.refreshCollectionPromise({collection: 'kits', sort : 'order', order : 'asc'}),
      this.refreshCollectionPromise({collection: 'products'}),
      this.refreshCollectionPromise({collection: 'subcategories'}),
    ])

    this.sections = this.kits.filter(item => item.category.is_gym_category).map(item => {
      item.products = this.products.filter(p => p.kit._id === item._id);
      let subs = item.products.map(s => s.subcategory_id);
      item.items = this.subcategories.filter(sub => subs.includes(sub._id));
      return item;
    });

    if(this.loggedIn) {
      await this.loadPurchases()
    }
    this.loading = false;
  }
}
</script>

<style scoped>

</style>