import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#f39530',
        secondary: '#1A1A1A',
        accent: '#f39530',
        error: '#d43a2d',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#f7d137'
      },
      dark: {
        primary: '#f39530',
        secondary: '#1A1A1A',
        accent: '#f39530',
        error: '#d43a2d',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#f7d137'
      },
    },
  },
});
