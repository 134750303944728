<template>
  <v-container>

    <v-row v-if="item._id">
      <v-col cols="12" lg="6" xl="4">
        <v-img :aspect-ratio="16/9" :src="item.kit.cover.url" style="border-radius: 24px;"></v-img>
      </v-col>

      <v-col cols="12" lg="6" xl="8">
        <h2 class="no-select display-1 white--text fw-1 text-uppercase mb-4">{{item.kit.title.it}} - {{item.title.it}}</h2>

        <v-row>
          <v-col cols="12">
            <v-divider dark class="mb-3"/>
          </v-col>
          <v-col :cols="isHomeKit ? 6 : 4">
            <p class="no-select subtitle-2 text-uppercase white--text">categoria</p>
            <p class="no-select title accent--text text-uppercase">{{item.kit.category.name.it}}</p>
          </v-col>
          <v-col v-if="!item.is_seats_product" :cols="isHomeKit ? 6 : 4">
            <p class="no-select subtitle-2 text-uppercase white--text">ideato per</p>
            <p class="no-select title accent--text text-uppercase">{{item.kit.genre.name.it}}</p>
          </v-col>
          <v-col cols="4" v-if="!isHomeKit && !item.is_seats_product">
            <p class="no-select subtitle-2 text-uppercase white--text">difficoltà</p>
            <v-chip class="no-select d-inline-flex" :color="difficultyColor(item.kit.difficulty)">{{item.kit.difficulty.abbrev.it}}</v-chip>
          </v-col>
          <v-col cols="12">
            <v-divider  dark/>
          </v-col>
          <v-col cols="12">
            <p class="no-select subtitle-2 text-uppercase white--text">descrizione</p>
            <p class="no-select body-1 white--text">{{item.description.it}}</p>
            <p class="no-select body-1 white--text" v-if="item.days_interval > 1">Si Consiglia una lezione ogni {{item.days_interval}} giorni</p>
            <p class="no-select body-1 white--text" v-if="item.days_interval === 1">Si consiglia una lezione al giorno</p>
          </v-col>

        </v-row>

      </v-col>
      <v-col cols="12" v-if="!isHomeKit && !item.is_seats_product">
        <v-divider  dark/>
      </v-col>
      <v-col cols="12" class="fill-height d-flex align-center" v-if="!isHomeKit && !item.is_seats_product">
        <h3 class="no-select headline white--text fw-1 text-uppercase mt-3">LEZIONI IN QUESTO ALLENAMENTO</h3>
      </v-col>
      <v-col cols="12" v-if="!isHomeKit && !item.is_seats_product">
        <v-divider  dark/>
      </v-col>
      <v-col cols="12" v-if="!isHomeKit && !item.is_seats_product">
        <v-row v-for="(lesson, i) in lessons" :key="lesson._id" class="pa-3" >
          <v-col cols="12" class="fill-height d-flex align-center" v-ripple="isDone(lesson._id) ? { 'class': 'accent--text' } : false" @click="goToLesson(lesson)">
            <div class="d-flex flex-shrink-0 flex-grow-1 justify-self-start">
              <span class="no-select title white--text">{{i+1}}</span>
              <v-icon color="white">mdi-chevron-right</v-icon>
              <span :class="`text-uppercase no-select ${lessonClass(lesson._id)}`">{{lesson.title.it}}</span>
              <v-icon class="ml-2" v-if="isDone(lesson._id)" color="success">mdi-check</v-icon>
              <v-spacer />
              <v-icon class="mr-2" v-if="isToDo(lesson._id) || isOngoing(lesson._id)" color="accent">mdi-play-circle-outline</v-icon>
            </div>
          </v-col>
          <v-col cols="12">
            <v-divider dark></v-divider>
          </v-col>
        </v-row>
      </v-col>


    </v-row>


  </v-container>
</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import config from '../config'
export default {
  name: "Training",
  data () {
    return {
      purchase : null,
      item : {
        title : { it : '' },
        description : { it : '' },
        days_interval : 1,
        sku : '',
        price : 0,
        expedition : false,
        last_update : new Date(),
        kit : {
          title : { it : '' },
          description : { it : '' },
          category : {
            name : { it : '' },
            abbrev : { it : '' },
          },
          genre : {
            name : { it : '' },
            abbrev : { it : '' },
          },
          difficulty : {
            name : { it : '' },
            abbrev : { it : '' },
            value : 0,
          },
          cover : {
            url : ''
          },
          icon : {
            url : ''
          },
          last_update : new Date(),
          pub : false
        },

      },
      lessons : []
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','kits','purchases','usablePurchases','ongoingLessons','doneLessons','cart','orientation','isAndroid']),
    loggedIn() {
      return this.profile != null
    },
    isHomeKit() {
      return this.item.sku === config.homeKitSkuF || this.item.sku === config.homeKitSkuM;
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
  },
  methods : {
    ...mapActions(['load', 'get', 'sendError', 'loadPurchases', 'refreshCollection', 'requestConfirm','notFound','loadLessons']),
    isOngoing( id ){
      return this.purchase && this.purchase.ongoing_lessons && this.purchase.ongoing_lessons.includes(id);
    },
    isDone( id ){
      return this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(id);
    },
    isToDo( id ){
      if(this.isOngoing(id)) return false;
      if(this.isDone(id)) return false;

      /* WTF?
      for(let i = 0; i < this.lessons.length; i++) {
        let currentId = this.lessons[i]._id;
        if(this.isOngoing(currentId)) {
          return false;
        }
        let done = this.isDone(currentId);
        if(!done) {
          return  currentId === id;
        }
      }*/
      return true;
    },
    lessonClass(id) {
      if(this.isOngoing(id)) {
        return 'title accent--text underline pointer'
      }
      if(this.isDone(id)) {
        return 'title grey--text underline text--darken-2 fw-1 pointer'
      }
      if(this.isToDo(id)) {
        return 'title accent--text underline pointer'
      }
      return 'title white--text';
    },
    difficultyColor(difficulty) {
      switch (difficulty.value) {
        case 1:
          return 'success'
        case 2:
          return 'lime'
        case 3:
          return 'warning'
        case 4:
          return 'error'
      }
      return 'grey'
    },
    goToLesson( lesson ) {
      if(this.isDone(lesson._id)) {
        return;
      }

      if(this.isAndroid && !lesson.android_video_mode) {
        this.$router.push(`/trainings2/${this.$route.params.id}/${lesson._id}`);
        return
      }
      this.$router.push(`/trainings/${this.$route.params.id}/${lesson._id}`);
    },
    loadData() {


      this.get({ collection : 'products', id: this.purchase.product._id +'?display=true'}).then((reply)=>{
        if(!reply.data) {
          this.notFound();
          return
        }
        this.item = reply.data;
        this.loading = false;
      }).catch((err) => {
        if(config.debug) console.error(err);
        this.loading = false;
        this.notFound();
      })
    }
  },
  mounted() {
    this.loading = true;

    const catcher = (err)=>{
      if(config.debug) console.error(err);
      this.loading = false;
      this.$router.go(-1);
    };

    this.loadPurchases().then(()=>{
      let filter = this.usablePurchases.filter((item) => item._id === this.$route.params.id);
      if(!filter.length) {
        this.$router.replace('/');
        return
      }

      this.purchase = filter[0];

      this.loadLessons({product_id: this.purchase.product._id}).then((lessons)=> {
        //console.log("lessons",lessons)
        this.lessons = lessons
        this.loadData();
      }).catch(catcher)
    }).catch(catcher)

  }
}
</script>
