<template>
    <v-container fluid class="fill-height" dark >
        <v-row>
            <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" class="pa-0">
                <v-row>
                    <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
                        <v-img class="flex-grow-0" alt="Top Up Your Personal Gym logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select text-center" >ACQUISTO COMPLETATO! ORA PUOI ALLENARTI!</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn class="flex-grow-0 flex-shrink-1" rounded color="accent" @click="$router.replace('/')">torna alla home</v-btn>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    import config from "../config";
    import { mapActions } from 'vuex'
    export default {
        name: "Paid",
        data() {
            return {
            };
        },
        computed: {
            loading : {
                set(newVal) {  this.$store.state.loading = newVal },
                get() { return this.$store.state.loading }
            },
        },
        methods: {
            ...mapActions(['loadProfile', 'loadPurchases', 'newCart'])

        },
        mounted() {
            this.loading = true
            this.newCart();
            setTimeout(() => {
                this.loadProfile().then(() => {
                    this.loadPurchases().then(() => {
                        this.loading = false;
                    }).catch((err) => {
                        if(config.debug) console.error(err);
                        this.loading = false;
                    })
                }).catch((err) => {
                    if(config.debug) console.error(err);
                    this.loading = false;
                })
            }, 500)

        }
    };
</script>
