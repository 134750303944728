<template>
  <v-container fluid class="fill-height" dark >
    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" class="pa-0">
        <v-row>
          <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
            <v-img class="flex-grow-0" alt="Top Up Your Personal Gym logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select text-center" >OOPS! C'È STATO UN ERRORE!</h2>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <p class="title white--text full-width fw-3 text-center"><b>Il nostro server riporta questo messaggio:</b> {{error}}</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <p class="title white--text full-width fw-3 text-center no-select">Se hai bisogno di aiuto puoi contattarci su whatsapp al seguente numero <a href="tel:+393488754794">+393488754794</a> dal lunedì al venerdì dalle 7 alle 21</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <p class="title white--text full-width fw-3 text-center no-select">Ci scusiamo per il disagio.</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn class="flex-grow-0 flex-shrink-1" rounded color="accent" @click="$router.replace('/')">Torna alla home</v-btn>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import config from "../config";
import {mapActions, mapGetters} from 'vuex'
export default {
  name: "Paid",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['paymentError']),
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    error() {
      return this.paymentError ? this.paymentError : 'errore sconosciuto'
    }
  },
  methods: {
    ...mapActions(['loadProfile', 'loadPurchases', 'newCart'])

  },
  mounted() {
    this.loading = true
    this.newCart();
    setTimeout(() => {
      this.loadProfile().then(() => {
        this.loadPurchases().then(() => {
          this.loading = false;
        }).catch((err) => {
          if(config.debug) console.error(err);
          this.loading = false;
        })
      }).catch((err) => {
        if(config.debug) console.error(err);
        this.loading = false;
      })
    }, 500)

  }
};
</script>
