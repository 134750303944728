<template>
  <v-container class="grid-list-md" >
    <v-row>
      <v-col cols="12">
        <h1 class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">I MIEI ACQUISTI</h1>
      </v-col>
    </v-row>
    <v-divider dark class="mb-4 mt-4"/>
    <v-progress-linear indeterminate v-if="loading" color="accent" rounded></v-progress-linear>
    <v-row v-if="purchases.length">
      <v-col cols="12" :sm="purchases.length > 1 ? 6 : 12" :lg="purchases.length > 2 ? 4 : 6" :xl="purchases.length > 3 ? 3 : 4" v-for="item in purchases" :key="item._id">
        <v-card @click="onItemSelect(item)" :class="item.consumed || isExpired(item.expiration) ? 'expired' : ''" >
          <v-img :aspect-ratio="16/9" :src="coverUrl(item.product._id)">
            <v-chip v-if="!item.product.is_seats_product" class="no-select ma-3 text-uppercase fw-6" :color="difficultyColor(item.product._id)">{{difficultyName(item.product._id)}}</v-chip>
          </v-img>
          <v-card-text>
            <h3 class="no-select title d-inline-flex">{{getName(item.product._id)}}</h3>
            <p v-if="item.product.is_seats_product" class="no-select subtitle-1 mb-0 accent--text font-weight-bold text-uppercase">{{categoryName(item.product._id)}}</p>
            <p v-else class="no-select subtitle-1 mb-0 accent--text font-weight-bold text-uppercase">{{categoryName(item.product._id)}} - {{genreName(item.product._id)}}</p>
            <p v-if="item.consumed" class="success--text">COMPLETATO</p>
            <p>Acquistato il {{parseDate(item.date)}}</p>
            <p v-if="item.expiration && !item.consumed && !isExpired(item.expiration)">Scade il {{parseDate(item.expiration)}}</p>
            <p v-if="item.expiration && !item.consumed && isExpired(item.expiration)">Scaduto il {{parseDate(item.expiration)}}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" >
        <p class="no-select subtitle-1 mb-0 font-italic white--text d-inline-flex align-center">al momento non hai allenamenti a disposizione, <v-btn class="d-inline-flex pa-1" rounded text color="accent" @click="$router.push('/')"><span class="underline tolower no-select">acquistane uno!</span></v-btn></p>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import config from "../config";
import moment from "moment";
import utils from "@/utils";
export default {
  name: "MyTrainings",
  data () {
    return {
      seatSku : config.seatSku,
      stopPropagation : false,
      loading : false,
    }
  },
  computed : {
    ...mapGetters(['token', 'profile','bgImage','kits','products', 'purchases','ongoing','categories','genre','difficulties']),
    loggedIn() {
      return this.profile != null
    },
    notLoggedDialog: {
      set(newVal) {  this.$store.state.notLoggedDialog = newVal },
      get() { return this.$store.state.notLoggedDialog }
    },
  },
  methods: {
    ...mapActions(['logout', 'load', 'sendError','refreshCollection','requestConfirm','onScroll','refreshCollectionPromise','removeFromCart','saveUserCart','loadPurchases']),
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY [alle] HH:mm")
    },
    isExpired(date) {
      return utils.isExpired(date)
    },
    coverUrl(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.cover.url
      }
      return '';
    },
    getName(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.title.it + ' - ' + p.title.it;
      }
      return 0;
    },
    getPrice(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.price;
      }
      return 0;
    },
    categoryName(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.category.name.it
      }
      return '';
    },
    genreName(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.genre.name.it
      }
      return '';
    },
    difficultyName(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.difficulty.name.it
      }
      return '';
    },
    difficultyColor(id) {
      const p = this.getProduct(id);
      if(p) {
        switch (p.kit.difficulty.value) {
          case 1:
            return 'success'
          case 2:
            return 'lime'
          case 3:
            return 'warning'
          case 4:
            return 'error'
        }
      }

      return 'white'
    },

    onItemSelect(item) {
      if(this.stopPropagation) {
        this.stopPropagation = false;
        return
      }

      if(item.product_sku === config.seatSku) {
        this.$router.push('/seats');
        return
      }

      if(item.consumed || this.isExpired(item.expiration)) {
        this.$router.push('/products/' + item.product.kit_id + "?p=" + item.product._id);
        return
      }
      this.$router.push('/trainings/' + item._id);
    },

    getKit(id) {
      for(let i = 0; i < this.kits.length; i++) {
        if(this.kits[i]._id === id) return this.kits[i];
      }
      return null
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },

  },
  mounted() {
    this.loading = true;
    const catcher = (err) => {
      if(config.debug) {
        console.error(err)
      }
      if(err.status === 401) {
        this.logout();
      }
    }
    this.refreshCollectionPromise({collection: 'kits'}).then(()=>{
      this.refreshCollectionPromise({collection: 'products'}).then(()=>{
        this.loadPurchases().then(()=>{
          this.loading = false;
          this.$forceUpdate();
        }).catch(catcher);
      }).catch(catcher);
    }).catch(catcher);



    /*
    this.refreshCollection({collection: 'products'});
    this.refreshCollection({collection: 'categories'});
    this.refreshCollection({collection: 'genre'});
    this.refreshCollection({collection: 'difficulties'});*/
  }
}
</script>

<style scoped>

</style>