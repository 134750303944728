<template>
  <div style="width: 100%; height: 100%">
    <video ref="video"  preload muted playsinline @ended="onVideoEnd" @timeupdate="onVideoProgress" class="full-bg-video" ></video>

    <div id="workout-content" :class="contentClass" style="display: grid; position: fixed; width: 100% !important;">

      <v-btn fab color="accent" small top right fixed @click="$router.go(-1)">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn fab color="error" small top right fixed @click="debugEnd()" v-if="debug" class="mr-12">
        <v-icon>mdi-bug</v-icon>
      </v-btn>
      <v-btn fab color="error" small top right fixed @click="debugNext()" v-if="debug" class="mr-12" style="right: 64px">
        <v-icon>mdi-skip-forward</v-icon>
      </v-btn>
      <audio ref="beep" src="../assets/beep.mp3" preload style="position: fixed; visibility: hidden; width: 0px; height: 0px"></audio>
      <audio ref="audio" :src="exercise.audio.url" preload style="position: fixed; visibility: hidden; width: 0px; height: 0px" />

      <v-row class="align-self-center justify-self-center justify-center" v-if="ended" >
        <v-col cols="12" class="pl-3 pr-3 text-center" style="z-index: 42; ">
          <p class="success--text display-1 fw-1">LEZIONE COMPLETATA!</p>
          <v-icon size="200" color="success">mdi-check-circle-outline</v-icon>
        </v-col>
      </v-row>

      <div :class="timerWrapClass"
           style="z-index: 42; ">
        <v-row class="align-self-center justify-self-center justify-center"  v-if="!ended">
          <v-col cols="12" class="d-flex justify-center">
            <circular-count-down-timer
                :key="keyUpdater"
                :class="isRecover ? 'error--text text-center text-shadow' :  'success--text text-center text-shadow title'"
                ref="countdown"
                :initial-value="currentCountdown"
                :stroke-width="12"
                :seconds-stroke-color="isRecover ? '#d43a2d' : '#4CAF50'"
                :underneath-stroke-color="'rgba(255,255,255,.6)'"
                :seconds-fill-color="'rgba(0,0,0,.6)'"
                :second-label="currentLabel"
                :size="timerSize"
                :padding="4"
                :show-hour="false"
                :show-minute="false"
                :show-second="true"
                :show-negatives="false"
                :paused="paused"
                @update="onTimerUpdate"
                @finish="onSessionEnd"
            ></circular-count-down-timer>
          </v-col>
        </v-row>
      </div>

      <div style="position: absolute; bottom: 0; right: 0;" class="d-flex full-width align-center justify-center">
        <v-btn icon color="accent" @click="askRestart()"><v-icon>mdi-skip-backward</v-icon></v-btn>
        <v-btn icon color="accent" width="90" height="90" :class="buttonDistanceClass" v-if="paused" @click="play()"><v-icon :size="playPauseSize">mdi-play-circle-outline</v-icon></v-btn>
        <v-btn icon color="accent" width="90" height="90" :class="buttonDistanceClass" v-else @click="pause()"><v-icon :size="playPauseSize">mdi-pause-circle-outline</v-icon></v-btn>
        <v-btn icon color="accent" @click="openHelpVideo()"><v-icon>mdi-help</v-icon></v-btn>
      </div>

      <div style="width: 100%; position: absolute; top: 0; left: 0" class="pt-5 pl-6 pr-6">
        <p class="white--text title mb-1" style="text-shadow: 0px 0px 3px black;">SESSIONE {{currentSchedule+1}} di {{item.schedules.length}}</p>
        <p class="white--text title text-uppercase" style="text-shadow: 0px 0px 3px black;" >ESERCIZIO: {{exercise.title.it}}</p>
      </div>


      <v-dialog :width="exerciseDialogWidth" v-model="exerciseDialog" dark class="border-rounded">
        <v-card dark class="border-rounded">
          <v-row class="ma-0 pa-6">
            <v-col cols="12">
              <h3 class="title white--text">{{exercise.title.it}}</h3>
            </v-col>
            <v-col cols="12">
              <p class="body-1 white--text mb-0">{{exercise.description.it}}</p>
            </v-col>
          </v-row>
          <v-btn absolute top right icon color="accent" @click="exerciseDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card>
      </v-dialog>

    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'
import config from '../config'
import utils from '../utils'
export default {
  name: "Workout",
  data () {
    return {
      debug : config.debug,
      privExerciseDialog : false,
      keyUpdater : 0,
      currentCountdown : 45,
      currentLabel : 'PREPARATI!',
      paused : true,
      item : {
        title : { it : '' },
        product_id : null,
        video : null,
        schedules : [],
        exercises : [],
        last_update : new Date(),
      },
      purchase : null,
      exercise : {
        title : { it : '' },
        description : { it : '' },
        video : { url : '' },
        audio : { url : '' },
        video_loop_start : 0,
        video_loop_end : 0,
      },
      currentSchedule : 0,
      isRecover: true,
      ended : false
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','kits','purchases','products','orientation',
      'ongoingLessons','doneLessons','cart','exercises','usablePurchases']),
    isSmallScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return false
        case 'xl': return false
      }
    },
    buttonDistanceClass() {
      return "ml-3 mr-3";
    },
    contentClass() {
      if(this.orientation === 'landscape') {
        return "align-end justify-end fill-height full-width overflow-hidden pa-2 ma-0";
      }
      return "align-end justify-center fill-height full-width overflow-hidden pa-0 ma-0"
    },
    timerWrapClass() {
      if(this.isSmallScreen) {
        return 'align-self-center';
      }
      return this.orientation === 'portraits' ? 'align-self-center' : 'mr-12 pr-12 align-self-center';
    },
    timerSize() {
      if (this.orientation === 'landscape') {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 90
          case 'sm': return 120
          case 'md': return 160
          case 'lg': return 160
          case 'xl': return 200
        }
      }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 200
        case 'sm': return 250
        case 'md': return 300
        case 'lg': return 300
        case 'xl': return 350
      }
      return 350;
    },
    playPauseSize() {

      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 50
        case 'sm': return 60
        case 'md': return 70
        case 'lg': return 80
        case 'xl': return 80
      }
      return 350;
    },
    currentTime : {
      set(newVal) {
        if(this.$refs.countdown)
          this.$refs.countdown.updateTime(newVal)
      },
      get() {
        if(this.$refs.countdown) return this.$refs.countdown.value;
        return this.currentCountdown;
      }
    },
    exerciseDialog : {
      set(newVal) {
        this.privExerciseDialog = newVal
      },
      get() {
        return this.privExerciseDialog
      }
    },
    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    exerciseDialogWidth() {
      if(window.innerWidth > window.innerHeight) {
        return "55%";
      }
      return "90%"
    },
  },
  methods : {
    ...mapActions(['load', 'get', 'sendError', 'loadPurchases', 'refreshCollection',
      'refreshCollectionPromise', 'requestConfirm','notFound','loadLesson','setOngoing','setOngoingWorkout']),
    openHelpVideo() {
      this.pause();
      this.exerciseDialog = true;
    },
    onTimerUpdate() {
      if(this.$refs.audio) {
        if(this.isRecover && Math.round(this.currentTime) === Math.round(this.currentCountdown) - 2) {
          this.$refs.audio.play();
        }
      }
      if(this.$refs.beep) {
        if(Math.round(this.currentTime - this.$refs.beep.duration) === 0) {
          this.$refs.beep.play();
        }
      }
      if(this.$refs.video) {
        this.$refs.video.volume = 0;
      }
      //console.log(`timer update isRecover ${this.isRecover}, currentTime ${this.currentTime}, audio duration ${this.$refs.audio.duration}`);

    },
    onVideoProgress(e) {
      const dom = e.target;
      if(this.exercise.video_loop_end !== 0 && dom.currentTime >= this.exercise.video_loop_end) {
        dom.currentTime = this.exercise.video_loop_start;
        dom.play();
      }


    },
    onVideoEnd(e) {
      const dom = e.target;
      dom.play();
      if(this.exercise.video_loop_end !== 0 && dom.currentTime >= this.exercise.video_loop_end) {
        dom.currentTime = this.exercise.video_loop_start;
      }
    },
    getExercise(id) {
      for(let i = 0; i < this.exercises.length; i++) {
        if(this.exercises[i]._id === id) return this.exercises[i];
      }
      return null
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    difficultyColor(difficulty) {
      switch (difficulty.value) {
        case 1:
          return 'success'
        case 2:
          return 'lime'
        case 3:
          return 'warning'
        case 4:
          return 'error'
      }
      return 'grey'
    },
    play() {
      if(!this.paused) return;
      if(!this.isRecover) {
        this.$refs.video.play();
        this.$refs.video.currentTime = this.exercise.video_loop_start;
      }
      this.paused = false;
    },
    pause() {
      if(this.paused) return;
      this.$refs.video.pause();
      this.paused = true
    },
    askRestart() {
      this.pause();
      this.requestConfirm({title: "Ricomincia", message: "Vuoi davvero ricominciare la lezione daccapo?", callback: (yes) => {
          if(yes) {
            this.currentSchedule = 0;
            this.isRecover = true;
            this.currentLabel = 'PREPARATI'
            this.currentCountdown = this.currentTime = this.item.schedules[this.currentSchedule].recovery_time;
            this.exercise = this.getExercise(this.item.schedules[this.currentSchedule].exercise_id);
            this.$refs.video.currentTime = this.exercise.video_loop_start
            this.startNextSession();
          }
        }});
    },
    startNextSession() {
      this.keyUpdater++;
      this.exercise = this.getExercise(this.item.schedules[this.currentSchedule].exercise_id);

      this.setOngoingWorkout({purchase_id: this.$route.params.purchase_id, lesson_id: this.$route.params.id, schedule: this.currentSchedule })

      if(this.$refs.video.src !== this.exercise.video.url) {
        this.$refs.video.src = this.exercise.video.url;
        this.$refs.video.load();
      }
      if(this.isRecover || this.$refs.video.readyState === 4) {
        this.play();
      } else {
        this.$refs.video.addEventListener('canplay', () => {
          this.play();
        }, { once : true})
      }
    },
    onSessionEnd() {
      this.pause();
      if(this.isRecover) {
        this.currentCountdown = this.currentTime = this.item.schedules[this.currentSchedule].work_time
        this.isRecover = false;
        this.currentLabel = 'LAVORO'
      } else {
        this.currentSchedule++
        if(this.currentSchedule >= this.item.schedules.length) {
          this.allFinished();
          return
        }

        this.currentCountdown = this.currentTime = this.item.schedules[this.currentSchedule].recovery_time
        this.isRecover = true;
        this.currentLabel = 'RECUPERO'
        if(this.item.schedules[this.currentSchedule].recovery_time === 0) {
          this.onSessionEnd();
          return;
        }
      }
      this.startNextSession()
    },
    allFinished() {
      this.setOngoingWorkout({purchase_id:null, lesson_id: null, schedule: null})
      this.pause();
      this.ended = true;
      setTimeout(()=>{
        this.$router.replace(`/report/${this.$route.params.purchase_id}/${this.$route.params.id}`);
      }, 4000);
    },
    debugNext() {
      this.onSessionEnd();
    },
    debugEnd() {
      this.allFinished();
    }
  },
  mounted() {
    this.loading = true;
    const catcher = (err)=>{
      if(config.debug) console.error(err);
      this.loading = false;
      this.$router.go(-1);
    };

    this.loadPurchases().then(()=>{
      let filter = this.usablePurchases.filter((item) => item._id === this.$route.params.purchase_id);
      if(!filter.length) {
        this.notFound();
        return
      }

      this.purchase = filter[0];
      if(this.purchase.done_lessons && this.purchase.done_lessons.includes(this.$route.params.id)) {
        this.notFound();
        return;
      }
      this.refreshCollectionPromise({collection: 'exercises'}).then(()=>{
        this.loadLesson({product_id: this.purchase.product._id, id: this.$route.params.id}).then((lesson)=> {
          this.item = lesson;
          //this.product = this.getProduct(this.$route.params.product_id)
          this.loading = false;

          let resume = localStorage.resume;
          if(resume) {
            let [purchase_id, lesson_id, schedule] = resume.split('|')
            if(purchase_id === this.purchase._id && lesson_id === lesson._id && schedule) {
              schedule = utils.toInt(schedule, 0)
              this.currentSchedule = schedule;
            }
          }


          this.currentCountdown = this.currentTime = this.item.schedules[this.currentSchedule].recovery_time;
          this.startNextSession();
          this.setOngoing({ purchase_id : this.purchase._id, lesson_id : lesson._id })
        }).catch(catcher);
      }).catch(catcher);
    }).catch(catcher);
  }
}
</script>
