<template>
  <div style="width: 100%; height: 100%">
    <video class="full-bg-video" ref="video" muted playsinline @ended="onVideoEnd" @timeupdate="onVideoProgress"></video>
    <div id="lesson-content" class="fill-height full-width overflow-hidden pa-0 ma-0">

      <v-btn fab color="accent" small top right fixed @click="$router.go(-1)">
        <v-icon>mdi-close</v-icon>
      </v-btn>



      <v-row style="position: fixed" class="full-width fill-height" @click="playVideoIfPaused()">
        <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2" lg="6" offset-lg="3" class="pa-0 fill-height" >




          <v-row class="pl-6 pr-6 pb-0 pt-1 overflow-y-auto fill-height" style="background: rgba(0,0,0,.4); box-sizing: content-box">
            <v-col cols="12" class="pt-12">
              <h2 class="no-select headline accent--text fw-1 text-uppercase">{{item.title.it}}</h2>
            </v-col>
            <v-col cols="12">
              <h2 class="no-select title white--text mb-2">{{item.schedules.length}} sessioni di esercizio</h2>
            </v-col>
            <v-col cols="12" v-if="item.description && item.description.it">
              <p class="no-select white--text mb-2">{{item.description.it}}</p>
            </v-col>
            <v-col cols="12">
              <v-divider dark class="mb-2"/>
            </v-col>
            <v-col cols="12">
              <h2 class="no-select title white--text mb-1 fw-1 text-uppercase">Anteprima degli esercizi in questa lezione</h2>
            </v-col>
            <v-col cols="12" style="padding-bottom: 72px;">
              <v-expansion-panels flat dark>
                <v-expansion-panel v-for="(ex, i) in item.exercises" :key="'ex'+i" class="pa-0" style="background: rgba(0,0,0,0)">
                  <v-expansion-panel-header @click="selectExercise(i)" class="pa-0"><h4 class="body-1 accent--text pointer mr-5 d-flex align-center"><v-icon class="mr-2 white--text">mdi-chevron-right</v-icon><span class="underline">{{ex.title.it}}</span></h4></v-expansion-panel-header>
                  <v-expansion-panel-content class="white--text ma-2">
                    {{ex.description.it}}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

          </v-row>
        </v-col>
      </v-row>


      <v-btn rounded color="accent" x-large bottom right fixed @click="startLesson()" >
        Avvia Lezione
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'
import anime from 'animejs/lib/anime.es.js';
import config from '../config'
export default {

  name: "Lesson",
  data () {
    return {
      currentExercise : 0,
      lessons : [],
      item : {
        title : { it : '' },
        product_id : null,
        video : {
          url : ''
        },
        schedules : [],
        exercises : [],
        exercises_video : [],
        exercises_audio : [],
        last_update : new Date(),
      },
      purchase : null,
      exercise : {
        title : {it : ''},
        description : {it : ''},
        video : {

        }
      },
      product : {
        title : { it : '' },
        description : { it : '' },
        days_interval : 1,
        sku : '',
        price : 0,
        expedition : false,
        last_update : new Date(),
        kit : {
          title : { it : '' },
          description : { it : '' },
          category : {
            name : { it : '' },
            abbrev : { it : '' },
          },
          genre : {
            name : { it : '' },
            abbrev : { it : '' },
          },
          difficulty : {
            name : { it : '' },
            abbrev : { it : '' },
            value : 0,
          },
          cover : {
            url : ''
          },
          icon : {
            url : ''
          },
          last_update : new Date(),
          pub : false
        },
      }
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','kits','purchases','usablePurchases','products','ongoingLessons','doneLessons','cart','orientation','media','userInteracted']),
    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    isOngoing(){
      return this.purchase && this.purchase.ongoing_lessons && this.purchase.ongoing_lessons.includes(this.item._id);
    },
    isDone(){
      return this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(this.item._id);
    },
    exerciseDialogWidth() {
      if(window.innerWidth > window.innerHeight) {
        return "55%";
      }
      return "90%"
    },
    isNextLessonTodo() {
      for(let i = 0; i < this.lessons.length; i++) {
        const current = this.lessons[i]
        if(this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(current._id)) {
          continue;
        }
        if(current._id === this.item._id) {
          if(i > 0) {
            let prev = this.lessons[i-1]
            return this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(prev._id);
          }
          return true
        }
      }
      return false;
    }
  },
  methods : {
    ...mapActions(['load', 'get', 'sendError', 'loadPurchases', 'refreshCollection', 'refreshCollectionPromise',
      'requestConfirm','notFound','loadLesson','loadLessons','setOngoingWorkout']),
    selectExercise(i) {
      if(this.currentExercise === i) return
      this.currentExercise = i;
      this.loadNextVideo();
    },
    loadNextVideo() {
      this.exercise = this.item.exercises[this.currentExercise]
      if(!this.exercise || !this.exercise.video_id) {
        console.log("no exercise");
        return;
      }
      this.exercise.video = this.getVideo(this.exercise.video_id);
      if(this.$refs.video.src !== this.exercise.video.url) {
        this.$refs.video.src = this.exercise.video.url;
        this.$refs.video.load();
      }
      this.playVideo()
    },
    playVideo() {
      if(!this.userInteracted) {
        return;
      }
      this.$refs.video.play();
      this.$refs.video.currentTime = this.exercise.video_loop_start;
    },
    playVideoIfPaused() {
      if(this.$refs.video.paused) this.playVideo();
    },
    onVideoProgress(e) {
      const dom = e.target;
      if(this.exercise.video_loop_end !== 0 && dom.currentTime >= this.exercise.video_loop_end) {
        this.currentExercise++;
        if(this.currentExercise >= this.item.exercises.length) {
          this.currentExercise = 0;
        }
        this.loadNextVideo();
      }
    },
    onVideoEnd(e) {
      const dom = e.target;
      if(this.exercise.video_loop_end !== 0 && dom.currentTime >= this.exercise.video_loop_end) {
        this.currentExercise++;
        if(this.currentExercise >= this.item.exercises.length) {
          this.currentExercise = 0;
        }
        this.loadNextVideo();
      }
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    getVideo(id) {
      for(let i = 0; i < this.item.exercises_video.length; i++) {
        if(this.item.exercises_video[i]._id === id) return this.item.exercises_video[i];
      }
      return null
    },

    lessonClass(id) {
      if(this.isOngoing(id)) {
        return 'title warning--text underline'
      }
      if(this.isDone(id)) {
        return 'title grey--text text--darken-2 fw-1'
      }
      return 'title white--text';
    },
    difficultyColor(difficulty) {
      switch (difficulty.value) {
        case 1:
          return 'success'
        case 2:
          return 'lime'
        case 3:
          return 'warning'
        case 4:
          return 'error'
      }
      return 'grey'
    },
    startLesson() {
      if(this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(this.$route.params.id)) {
        this.sendError({message:'Hai già completato questa lezione!'});
        return
      }
      if(!this.isNextLessonTodo) {
        this.sendError({message:'Non puoi fare questa lezione: devi ancora completare le lezioni precedenti!'});
        return
      }
      this.setOngoingWorkout({purchase_id:null, lesson_id: null, schedule: null})
      this.$router.push(`/trainings/${this.$route.params.purchase_id}/${this.$route.params.id}/work`);
    }
  },
  mounted() {
    this.loading = true;
    const catcher = (err)=>{
      if(config.debug) console.error(err);
      this.loading = false;
      this.$router.go(-1);
    };
    this.loadPurchases().then(()=>{
      let filter = this.usablePurchases.filter((item) => item._id === this.$route.params.purchase_id);
      if(!filter.length) {
        this.notFound();
        return
      }

      this.purchase = filter[0];

      this.refreshCollectionPromise({collection: 'products'}).then(()=>{
        this.loadLessons({product_id: this.purchase.product._id}).then((lessons)=> {
          //console.log("lessons",lessons)
          this.lessons = lessons
        }).catch(catcher)
        this.loadLesson({product_id: this.purchase.product._id, id: this.$route.params.id + "?display=true"}).then((lesson)=> {
          this.item = lesson
          this.product = this.getProduct(this.purchase.product._id)
          /*
          if(!this.userInteracted) {
              this.isVideoPaused = true;
          }
           */
          this.loadNextVideo()
          this.$forceUpdate();
          this.loading = false;
        }).catch(catcher);
      }).catch(catcher);
    }).catch(catcher);
  }
}
</script>
