<template>
  <v-app dark style="background: #1A1A1A" v-scroll:#scroll-target="onScroll" >

    <v-img v-if="!isVideoBg" class="sfondo" style="position: fixed; width: 100%; height: 100%" :src="bg" />
    <v-app-bar app :style="appBarStyle" dark elevation="0" v-if="isAppBarRoute">
      <v-app-bar-nav-icon v-if="loggedIn && !advMode" @click.stop="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-if="isShowBack" @click.stop="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-app-bar-nav-icon>
      <div class="d-flex align-center pointer" v-if="!advMode">
        <v-img alt="Top Gym Logo" class="shrink mr-4 d-none d-md-flex" @click="goHome()"
               contain src="./assets/logo_root_w.png" transition="scale-transition" width="200" height="64"
        />
        <v-img alt="Top Gym Logo" class="shrink mr-4 d-md-none" @click="goHome()"
               contain src="./assets/logo_root_small.png" transition="scale-transition" width="43" height="32"
        />

        <v-img alt="Top Up Gym Logo" class="shrink mr-2 d-none d-md-flex" @click="goHome()" width="198"
                contain src="./assets/logo-esteso-alpha.png" transition="scale-transition" height="64"
        />
        <v-img alt="Top Up Gym Logo" class="shrink mr-2 d-md-none" @click="goHome()" width="32"
                contain src="./assets/logo-alpha.png" transition="scale-transition" height="32"
        />

      </div>
      <v-spacer />

        <v-btn rounded text color="accent" @click="goToCart()" v-if="!advMode"  :small="$vuetify.breakpoint.smAndDown"  >
          <v-badge inline
                  class="white--text"
                  :color="numItemsInCart === 0 ? 'grey' : 'primary'"
                  :content="numItemsInCartLabel">
          <v-icon>mdi-cart-outline</v-icon>
          </v-badge>
        </v-btn>


      <v-btn class="ml-1 ml-lg-3" v-if="!loggedIn && !advMode" :small="$vuetify.breakpoint.smAndDown" rounded color="accent" @click="$router.push('/login')"><p class="ma-1" style="line-height: 1">accedi<br><span style="font-size: 10px">o registrati</span></p></v-btn>
      <v-btn class="ml-1 ml-lg-3" v-if="loggedIn && !advMode" :small="$vuetify.breakpoint.smAndDown" rounded color="accent" @click="logout()">esci</v-btn>
    </v-app-bar>


    <v-navigation-drawer
            v-if="loggedIn"
            v-model="drawer"
            temporary
            width="280"
            color="secondary"
            dark
            app>
      <v-row>
        <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
          <v-img class="flex-grow-0" alt="Top Up Logo" width="174" height="197" src="./assets/logo-w-v.png" transition="scale-transition"></v-img>
        </v-col>
      </v-row>
      <v-list dense>
        <v-list-item v-for="page in pages" :key="page.link" :to="page.link" router active-class="accent--text">
          <v-list-item-content>
            <v-list-item-title class="title fw-1 text-uppercase" >{{page.title}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>{{page.icon}}</v-icon>
          </v-list-item-action>

        </v-list-item>
      </v-list>
      <p style="position: absolute; top: 2px; right: 6px" class="topup-version caption grey--text no-select">{{version}}</p>
    </v-navigation-drawer>


    <v-main >
      <v-row no-gutters style="padding-bottom: 96px">
        <v-col cols="12">
          <v-scroll-y-reverse-transition>
            <router-view />
          </v-scroll-y-reverse-transition>
        </v-col>

        <v-col cols="12" class="d-flex justify-center pa-3" v-if="bannerList.length">
          <h4 class="accent--text">OFFICIAL PARTNERS</h4>
        </v-col>
        <v-col cols="12" class="d-flex justify-center pa-3">

          <v-carousel cycle hide-delimiter-background show-arrows-on-hover style="max-width: 960px" hide-delimiters continuous  class="bann-aspect-ratio" height="auto" :interval="bannerDuration">
            <v-carousel-item
                :link="!!b.link"
                :href="b.link"
                v-for="b in bannerList"
                :key="b._id"
                target="_blank"
                :ripple="false"

            >
              <v-img :src="b.src" :title="b.title" :alt="b.title" :aspect-ratio="320/50" :max-width="960"></v-img>
            </v-carousel-item>
          </v-carousel>

        </v-col>
      </v-row>



      <v-dialog v-model="confirmDialog" persistent max-width="350" dark class="secondary">
        <v-card>
          <v-card-title class="headline fw-1 text-uppercase accent--text">{{confirmDialogTitle}}</v-card-title>
          <v-card-text>
            <p class="body-1" v-html="confirmDialogMessage"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="onConfirmDialogExit(false)">No</v-btn>
            <v-btn color="accent" text @click="onConfirmDialogExit(true)">Sì</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="privacyDialog" fullscreen dark class="secondary" hide-overlay transition="dialog-bottom-transition" scrollable >
        <v-card tile>
          <v-card-title class="headline fw-1 text-uppercase accent--text">PRIVACY POLICY</v-card-title>
          <v-card-text class="white--text">
            <h2 class="mb-3">Informativa sul trattamento dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 e del D.lgs. 196/2003 e successive modifiche</h2>
            <p>TopGym personal studio S.s.d.r.l. è il Titolare del trattamento (d’ora in avanti: “TopGym” o “Titolare”) ai sensi del Regolamento UE 679/2016 (d’ora in avanti: “Regolamento”) e del D.lgs. 196/2003 e successive modifiche (d’ora in avanti: “Codice Privacy”) e considera la privacy e la tutela dei tuoi dati personali uno degli obiettivi principali della propria attività. Ti invitiamo, dunque, a leggere con attenzione la presente Informativa Privacy perché contiene informazioni importanti sulla tutela dei tuoi dati personali.</p>
            <p>La presente Privacy Policy, in particolare: ti informa in merito ai tuoi diritti riguardo al trattamento dei tuoi dati personali; ti consente di conoscere in modo trasparente come raccogliamo e trattiamo i tuoi dati personali e come garantiamo la loro protezione; ti mostra le modalità con cui puoi esercitare i tuoi diritti e i modi in cui risponderemo alle tue richieste; costituisce parte integrante dei servizi che offriamo tramite la App TopUp; è resa ai sensi degli artt. 13 e 14 del Regolamento e ai sensi del D.lgs. 196/2003 a coloro che interagiscono con la App TopUp; non si intende specificamente riferita ai trattamenti di dati personali posti in essere da TopGym nel contesto del contratto associativo nei confronti dei propri iscritti presso la propria sede operativa in Piazzale Kennedy n. 1/B – 06012, Città di Castello (Pg).</p>
            <p>Indice dei contenuti:</p>
            <ol>
              <li>INFORMAZIONI GENERALI E DEFINIZIONI (GDPR)</li>
              <li>TITOLARE DEL TRATTAMENTO</li>
              <li>CATEGORIE DI INTERESSATI</li>
              <li>CATEGORIE DI DATI TRATTATI</li>
              <li>FONTI DI RACCOLTA DEI DATI PERSONALI</li>
              <li>FINALITA’ DEL TRATTAMENTO DEI DATI PERSONALI BASE GIURIDICA DEL TRATTAMENTO E CONSEGUENZE</li>
              <li>COMUNICAZIONE DEI DATI PERSONALI</li>
              <li>TRASFERIMENTI DEI DATI PERSONALI</li>
              <li>LUOGO DI CONSERVAZIONE</li>
              <li>LIMITI ALLA CONSERVAZIONE</li>
              <li>MISURE DI SICUREZZA</li>
              <li>DIRITTI DELL’INTERESSATO</li>
              <li>MODIFICHE</li>
              <li>COME CI PUOI CONTATTARE PER OTTENERE INFORMAZIONI</li>
            </ol>
            <h3 class="accent--text mt-3" >1. INFORMAZIONI GENERALI E DEFINIZIONI (GDPR)</h3>
            <p>Per «trattamento»: qualsiasi operazione o insieme di operazioni, compiute con o senza l’ausilio di processi automatizzati e applicate a dati personali o insiemi di dati personali, come la raccolta, la registrazione, l’organizzazione, la strutturazione, la conservazione, l’adattamento o la modifica, l’estrazione, la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, il raffronto o l’interconnessione, la limitazione, la cancellazione o la distruzione.</p>
            <p>Per compiere le attività di trattamento il Titolare può anche servirsi di soggetti esterni alla propria organizzazione, definiti Responsabili del trattamento.</p>
            <p>Tali Responsabili trattano i tuoi dati in base alle istruzioni del Titolare, e esclusivamente per le finalità stabilite dal Titolare. Responsabili del trattamento possono essere, per esempio, i soggetti che forniscono l’infrastruttura tecnologica della App TopUp.</p>
            <p>Per «dato personale»: qualsiasi informazione riguardante una persona fisica identificata o identificabile («interessato»); si considera identificabile la persona fisica che può essere identificata, direttamente o indirettamente, con particolare riferimento a un identificativo come il nome, un numero di identificazione, dati relativi all’ubicazione, un identificativo online o a uno o più elementi caratteristici della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale.</p>
            <p>Per «consenso dell’interessato»: qualsiasi manifestazione di volontà libera, specifica, informata e inequivocabile dell’interessato, con la quale lo stesso manifesta il proprio assenso, mediante dichiarazione o azione positiva inequivocabile, che i dati personali che lo riguardano siano oggetto di trattamento.</p>
            <h3 class="accent--text mt-3" >2. TITOLARE DEL TRATTAMENTO</h3>
            <p>TopGym personal studio S.s.d.r.l. (P. IVA 03503830543), con sede legale in via Risorgimento n. 17, Città di Castello (Pg) e sede operativa via Luca della Robbia 63, Città di Castello (Pg), in persona del legale rappresentante p.t. Stefano Scudero, (C.F. SCDSFN87H11E716Q), email topgymstudio@gmail.com e pec topgymstudio@pec.it.</p>
            <h3 class="accent--text mt-3" >3. CATEGORIE DI INTERESSATI</h3>
            <p>Il titolare del trattamento, durante ed al fine del corretto svolgimento della propria attività, può raccogliere, utilizzare, consultare e più in generale trattare i tuoi quale controparte del rapporto contrattuale.</p>
            <h3 class="accent--text mt-3" >4. CATEGORIE DI DATI TRATTATI</h3>
            <p>I dati personali trattati sono i seguenti:</p>
            <p>1. Dati di navigazione.</p>
            <p>I sistemi informatici e le procedure software preposte al funzionamento della App TopUp acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono alla App TopUp, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, etc.) ed altri parametri relativi al sistema operativo, al modello di device utilizzato e all'ambiente informatico dell'utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso della App TopUp per controllarne il corretto funzionamento, per identificare anomalie e/o abusi, e vengono cancellati immediatamente dopo l'elaborazione. I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni della App TopUp o di terzi.</p>
            <p>2. Dati forniti volontariamente da te. </p>
            <p>Salvo il richiamo a specifiche informative, la presente Privacy Policy si intende resa per il trattamento dei dati da te volontariamente forniti tramite la App TopUp, ovvero da te volontariamente forniti presso la sede (rappresentazioni fotografiche e riprese video).</p>
            <p>3. Categorie particolari di dati. </p>
            <p>La App TopUp è uno “strumento di allenamento” e si appoggia ad una piattaforma che permette di accedere in qualunque momento e luogo ai propri dati, contenuti, programmi e risultati di allenamento. Ciò comporta necessariamente il trattamento, da parte di TopGym, di dati personali che, nel loro complesso, possono rivelare il tuo stato di salute e che, pertanto, rientrano nel novero delle categorie particolari di dati personali di cui all'art. 9 del Regolamento. Infatti, la App TopUp fornisce un servizio che comporta il trattamento di dati personali quali, a titolo esemplificativo, nome e cognome, data di nascita, indirizzo email, oltre che informazioni relative al tuo peso e altezza, oltre a eventuale certificazione medica. A tal proposito, si chiarisce che le informazioni relative al peso e altezza sono necessarie per poter garantire le funzionalità della App TopUp sin dall'inizio, dal momento che tali informazioni risultano indispensabili perché la App TopUp possa essere efficacemente utilizzata come uno “strumento di allenamento”. </p>
            <p>La App TopUp consente altresì di acquistare i servizi offerti da TopGym personal studio S.s.d.r.l., con particolare riferimento all’abbonamento per l’accesso ai locali palestra, ove svolgere la propria attività sportiva. Per tale ragione personale appositamente incaricato potrà trattare tuoi dati particolari, rilevando temperatura corporea e certificato c.d. “green pass” ai sensi del Decreto Legge n. 127 del 21 settembre 2021 recante “Misure urgenti per assicurare lo svolgimento in sicurezza del lavoro pubblico e privato mediante l'estensione dell'ambito applicativo della certificazione verde COVID-19 e il rafforzamento del sistema di screening” che integra il precedente D.L. n. 52/2021 convertito, con modificazioni, dalla Legge 17 giugno 2021 n. 87. Tali informazioni sono necessarie per poter usufruire dei servizi offerti da TopGym.</p>
            <h3 class="accent--text mt-3" >5. FONTI DI RACCOLTA DEI DATI PERSONALI</h3>
            <p>TopGym raccoglie i tuoi dati personali in fase di registrazione del tuo Account nella App TopUp ovvero direttamente in sede tramite personale dedicato.<p>
            <p>Se risiedi in Umbria, quale dato aggiuntivo, TopGym raccoglierà il tuo codice fiscale e il certificato medico di idoneità sportiva tramite email o consegna a mani al fine di procedere al tuo preventivo e obbligatorio tesseramento come socio di TopGym.</p>
            <h3 class="accent--text mt-3" >6. FINALITÀ DEL TRATTAMENTO DEI DATI PERSONALI, BASE GIURIDICA DEL TRATTAMENTO E CONSEGUENZE</h3>
            <p>I tuoi dati personali, anche rientranti nel novero delle categorie particolari saranno trattati con strumenti automatizzati e non.  Essi verranno trattati, con il tuo consenso se necessario, per le seguenti finalità, ove applicabili:</p>
            <p>1. <em>Erogazione dei servizi</em>: consentire l’utilizzo della App TopUp e l’erogazione dei vari servizi forniti da TopGym, acquistabili tramite la App TopUp direttamente presso il locale palestra.</p>
            <p>2. <em>Riscontro delle richieste.</em></p>
            <p>Il trattamento dei tuoi dati personali per le finalità di Erogazione dei servizi e di Riscontro delle richieste risulta necessario per poterti erogare i servizi richiesti. Tale trattamento si fonda sull’art. 6, par. 1, lett. b, del Regolamento ([…]il trattamento è necessario all’esecuzione di un contratto di cui l’interessato è parte o all’esecuzione di misure precontrattuali adottate su richiesta dello stesso). Il conferimento dei tuoi dati personali per queste finalità è facoltativo, ma l’eventuale mancato conferimento comporterebbe l’impossibilità di attivare o erogare i servizi da te richiesti. Il trattamento dei tuoi dati personali relativi al tuo stato di salute si fonda sul tuo consenso, conformemente a quanto previsto dall’art. 9, par. 2, lett. a, del Regolamento ([…] l’interessato ha prestato il proprio consenso esplicito al trattamento di tali dati personali per una o più finalità specifiche), oltre che sull’Autorizzazione, pro tempore vigente, del Garante per la protezione dei dati personali. Il conferimento del tuo consenso è facoltativo, ma in mancanza non potremo fornirti il servizio richiesto e ciò a tutela della sicurezza ed efficacia dei tuoi allenamenti, nonché per il tuo tesseramento come socio se residente in Regione Umbria.</p>
            <p>3. <em>Compliance</em>: adempiere ad eventuali obblighi previsti dalle leggi vigenti, da regolamenti o dalla normativa comunitaria, o soddisfare richieste provenienti dalle autorità.</p>
            <p>3. <em>Legittimo interesse del Titolare</em>: tutela della sicurezza e del patrimonio aziendale.</p>
            <p>Il trattamento dei tuoi dati personali per tale finalità è necessario per adempiere ad obblighi di legge a cui TopGym è soggetta. Tale trattamento si fonda, infatti, sull’art. 6, par. 1, lett. c, del Regolamento ([…]il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il titolare del trattamento). Tale trattamento potrebbe comportare la conservazione e comunicazione dei tuoi dati personali alle autorità per obblighi contabili, fiscali o di altra natura.</p>
            <p>5. Svolgere <em>“Opt-in”</em> diretto via email per servizi analoghi a quelli da te sottoscritti, salvo il tuo rifiuto espresso a ricevere tali comunicazioni, che potrai esprimere in qualunque momento.</p>
            <p>Si precisa che il trattamento dei tuoi dati personali per questa finalità rappresenta un trattamento legittimo ai sensi dell’art. 130, comma 4, del Codice Privacy, che non necessita del tuo consenso, sempre che si tratti di servizi analoghi a quelli che sono già stati oggetto di un tuo acquisto e che tu, adeguatamente informato, non rifiuti tale uso, inizialmente o in occasione di successive comunicazioni ricevute. Ciò significa, che tu puoi opporti al trattamento dei tuoi dati personali per tale finalità sia in fase di richiesta dei prodotti e servizi disponibili sulla App TopUp (negando espressamente il consenso al trattamento per finalità di Marketing), che in occasione di successive comunicazioni commerciali correlate al tuo acquisto ricevute da parte del Titolare, scrivendo ai recapiti del Titolare.</p>
            <p>5. <em>Marketing</em>: svolgere attività di marketing come elaborare studi, ricerche, statistiche di mercato; inviarti materiale promozionale inerente alle attività, ai servizi e ai prodotti di TopGym.</p>
            <p>6. <em>Comunicazione dei tuoi dati a partner commerciali per finalità di marketing</em>: consentire a partner di TopGym di inviarti materiale promozionale inerente ad attività strettamente connesse alle attività, ai servizi e ai prodotti di TopGym;</p>
            <p>Tali comunicazioni potranno essere effettuate via email o via sms. Si precisa che il Titolare raccoglie un unico consenso per tutte le finalità di marketing qui descritte, ai sensi del Provvedimento Generale del Garante per la Protezione dei Dati Personali “Linee guida in materia di attività promozionale e contrasto allo spam”, del 4 luglio 2013. I trattamenti eseguiti per le finalità di Marketing descritto alla presente sezione richiedono il tuo preventivo consenso, ai sensi dell’art. 6, par. 1, lett. a ([…] l’interessato ha espresso il consenso al trattamento dei propri dati personali per una o più specifiche finalità).</p>
            <p>Il conferimento dei tuoi dati personali per questa finalità è quindi del tutto facoltativo e non pregiudica, in alcun modo, la fruizione dei servizi. Ciò significa che qualora tu non desideri rilasciare tale consenso, potrai ugualmente usufruire dei servizi resi disponibili mediante la App TopUp. L’unica conseguenza del tuo mancato rilascio di tale consenso, sarà che non potrai ricevere le offerte e promozioni di TopGym. In ogni caso, qualora desiderassi opporti al trattamento dei tuoi dati personali per le finalità di Marketing, potrai in qualunque momento farlo contattando il Titolare ai recapiti indicati.</p>
            <p>7. <em>Profilazione</em>: ove applicabile, analizzare i tuoi interessi, abitudini e scelte di consumo, al fine di poterti inviare materiale promozionale personalizzato sui servizi offerti da TopGym.</p>
            <p>Sul punto, si precisa che tale attività di Profilazione necessita del tuo preventivo consenso e consiste nel trattamento mediante strumenti automatizzati di informazioni relative ai tuoi interessi e preferenze di allenamento, oltre che alle tue abitudini di utilizzo dei nostri servizi. Ad esempio, una conseguenza di questo trattamento è che tu riceverai comunicazioni da TopGym su corsi, iniziative e promozioni che, sulla base delle tue abitudini e preferenze di utilizzo dei nostri servizi, riteniamo possano essere di tuo interesse. Tale attività di profilazione non ha, comunque, mai ad oggetto dati relativi al tuo stato di salute. Ti informiamo, in ogni caso, che non verrai sottoposto a processi decisionali automatizzati ai sensi dell’art. 22 del Regolamento, ossia non sarai sottoposto a decisioni basate unicamente sul trattamento automatizzato, che producano effetti giuridici che ti riguardano o che incidano significativamente sulla tua persona. I trattamenti eseguiti per le finalità di Profilazione descritti nella presente sezione richiedono il tuo preventivo consenso, ai sensi dell’art. 6, par. 1, lett. a ([…] l’interessato ha espresso il consenso al trattamento dei propri dati personali per una o più specifiche finalità). Il conferimento dei tuoi dati personali per questa finalità è quindi del tutto facoltativo e non pregiudica, in alcun modo, la fruizione dei servizi. Ciò significa che, qualora tu non desideri rilasciare tale consenso, potrai ugualmente usufruire dei servizi resi disponibili mediante la App TopUp. L’unica conseguenza del tuo mancato rilascio di tale consenso, sarà che non potrai ricevere comunicazioni promozionali in linea con i tuoi interessi. In ogni caso, qualora desiderassi opporti al trattamento dei tuoi dati personali per la finalità di Profilazione, potrai in qualunque momento farlo contattando il Titolare ai recapiti indicati.</p>
            <p>8. <em>Statistica</em>: pper scopi statistici, senza che sia possibile risalire alla tua identità: tale trattamento non è eseguito su dati personali e pertanto può essere liberamente effettuato da TopGym.</p>
            <h3 class="accent--text mt-3" >7. COMUNICAZIONE DEI DATI PERSONALI</h3>
            <p>I tuoi dati personali potranno essere condivisi, per le finalità di cui all’articolo 6 della presente Privacy Policy, con: </p>
            <ol>
              <li>soggetti che agiscono tipicamente in qualità di responsabili del trattamento, ossia persone, società o studi professionali che prestano attività di assistenza e consulenza a TopGym in materia contabile, amministrativa, legale, tributaria e finanziaria; soggetti delegati a svolgere attività di manutenzione tecnica dei sistemi informatici e della App TopUp; istituti di credito, compagnie e broker assicurativi (collettivamente “Destinatari”). A titolo esemplificativo, la App TopUp si appoggia su una piattaforma fornita da Amazon Web Services EMEA SARL, corrente in 38 Avenue John F. Kennedy, L-1855, Lussemburgo, il quale opera in qualità di responsabile del trattamento;</li>
              <li>soggetti, enti od autorità a cui sia obbligatorio comunicare i tuoi dati personali in forza di disposizioni di legge o di ordini delle autorità;</li>
              <li>persone autorizzate da TopGym al trattamento di dati personali necessario a svolgere attività strettamente correlate all’erogazione dei servizi, che si siano impegnate alla riservatezza o abbiano un adeguato obbligo legale di riservatezza, quali, a titolo esemplificativo, i dipendenti di TopGym.</li>
              <li>Terze parti, quali social network (Instagram, Facebook ecc.)</li>
            </ol>
            <p>In ogni caso i dati conferiti non saranno oggetto di diffusione se non imposta da una norma di legge o espressamente autorizzata dall’interessato.</p>
            <h3 class="accent--text mt-3" >8. TRASFERIMENTI DEI DATI PERSONALI</h3>
            <p>Il titolare del trattamento potrebbe trasmettere alcuni dati dell’interessato in paesi al di fuori dello Spazio Economico Europeo, ed in particolare nei confronti di soggetti erogatori di servizi internet (server provider, cloud provider ecc.). In tal caso il trasferimento avverrà in conformità delle decisioni di adeguatezza (quale es. privacy shield Ue-Usa). Invero, i trasferimenti si possono basare su una decisione di adeguatezza o sulle Standard Contractual Clauses approvate dalla Commissione Europea.</p>
            <p>Maggiori informazioni sono disponibili inviando una richiesta scritta al Titolare ai recapiti indicati.</p>
            <h3 class="accent--text mt-3" >9. LUOGO DI CONSERVAZIONE</h3>
            <p>Il trattamento avverrà mediante strumenti cartacei, informatici e telematici, esclusivamente ad opera di personale incaricato dal titolare del trattamento e appositamente formato, presso la sede operativa, nonché presso i responsabili esterni nominati presso le loro sedi legali e operative.</p>
            <h3 class="accent--text mt-3" >10. LIMITI ALLA CONSERVAZIONE</h3>
            <p>Conserveremo i tuoi dati personali per tempi diversi a seconda della finalità per cui li abbiamo raccolti:</p>
            <ol>
              <li>Erogazione dei servizi e di Riscontro delle richieste: conserveremo i dati per il tempo strettamente necessario per raggiungere le finalità. In ogni caso, trattandosi di trattamenti svolti per la fornitura di servizi, TopGym si avvarrà della facoltà, consentita dalla normativa (art. 2946, Codice Civile), di conservare i dati personali necessari a tutelare i propri interessi, per il tempo previsto dalla legge.</li>
              <li>Compliance: conserveremo i dati per il tempo previsto dallo specifico obbligo o norma di legge applicabile;</li>
              <li>“Soft Spam”: conserveremo i dati fino a che tu non ti sia opposto al trattamento, richiedendo di non essere più oggetto di comunicazioni commerciali;</li>
              <li>Marketing e Profilazione: come regola generale, fino alla revoca del tuo consenso.</li>
            </ol>
            <p>Maggiori informazioni in merito al periodo di conservazione dei dati e ai criteri utilizzati per determinare tale periodo possono essere richieste inviando una richiesta scritta ai recapiti indicati. È fatta salva in ogni caso la possibilità per TopGym di conservare i tuoi dati personali per il periodo di tempo previsto e ammesso dalla legge Italiana a tutela dei propri interessi (Art. 2947, comma 1 e 3, Codice Civile).</p>
            <h3 class="accent--text mt-3" >11. MISURE DI SICUREZZA</h3>
            <p>Il titolare del trattamento ai sensi dell’art. 25 Reg. Ue 679/2016 adotta misure di sicurezza fisiche, elettroniche e procedurali adeguate alla sensibilità delle informazioni in suo possesso. Tali misure variano a seconda della sensibilità, del formato, dell’ubicazione, della quantità e delle modalità di distribuzione e archiviazione dei dati personali e comprendono misure volte a proteggere i dati personali contro l’accesso non autorizzato. Le misure di sicurezza includono l’integrità dei dati trasmessi e ricevuti, firewall, antivirus, controlli degli accessi e delle autorizzazioni, autenticazione degli utenti, formazione degli incaricati, misure di disaster recovery.</p>
            <h3 class="accent--text mt-3" >12. DIRITTI DELL’INTERESSATO</h3>
            <p>L’interessato ha i seguenti diritti in materia di protezione dei dati personali che possono essere esercitati nei confronti del titolare del trattamento scrivendo al suo indirizzo email o mediante comunicazione da inviare a mezzo a.r. alla sede legale: il diritto di accesso (art. 15 Reg. UE 679/2016, i.e richiesta sulle modalità di utilizzo e trattamento dei dati personali); il diritto di rettifica (art. 16 Reg. UE 679/2016 i.e correzione di eventuali imprecisioni nei dati personali); il diritto di cancellazione (art. 17 Reg. UE 679/2016 cancellazione dei dati personali nel caso in cui non sussista più un fondamento giuridico che ne giustifichi il trattamento o se l’interessato revoca il consenso); il diritto di limitazione al trattamento (art. 18 GDPR i.e. l’Interessato contesta l’esattezza dei dati personali e la limitazione del trattamento dei dati personali avviene per il tempo necessario a prendere in esame la richiesta dell’interessato); il diritto alla portabilità (art. 20 Reg. UE 679/2016 i.e. ricezione dei dati personali e trasmissione ad altro titolare dei dati personali conservati; il diritto di opposizione (art. 21 Reg. UE 679/2016) i.e. diritto di opporsi in qualsiasi momento, al trattamento dei dati personali che lo riguardano; il diritto di revocare il proprio consenso al trattamento dei dati personali (art. 7 Reg. UE 679/2016 i.e. revocare il consenso al trattamento in qualsiasi momento in assenza di motivazioni); il diritto di inoltrare un reclamo all’autorità di controllo (art. 77 Reg. UE 679/2016). Il titolare del trattamento ed il Responsabile per la protezione dei Dati si impegnano a rispondere alle richieste di esercizio dei diritti di cui sopra entro trenta giorni dal loro ricevimento agli indirizzi indicati nella presente informativa.</p>
            <h3 class="accent--text mt-3" >13. MODIFICHE</h3>
            <p>La presente Informativa Privacy è in vigore dal 22/06/2020. Il titolare del trattamento si riserva di modificarne o semplicemente aggiornarne il contenuto, in parte o completamente, anche a causa di variazioni della normativa applicabile. Le modifiche saranno vincolanti non appena pubblicate sul sito. Si invita quindi a visitare con regolarità questa sezione per avere conoscenza della più recente ed aggiornata versione dell’informativa ed in modo che l’interessato sia sempre aggiornato sui dati raccolti e sull’uso che il titolare del trattamento ne fa. In ogni caso, qualora le modifiche comportino nuovi o ulteriori trattamenti e comunque nei casi previsti dalla normativa vigente, i dati o dati personali dell’Interessato non saranno oggetto di ulteriori trattamenti senza il suo esplicito consenso.</p>
            <h3 class="accent--text mt-3" >14. COME CI PUOI CONTATTARE PER OTTENERE INFORMAZIONI</h3>
            <p>Per esercitare i tuoi diritti privacy o per qualunque altra richiesta di informazioni riguardo il trattamento dei tuoi dati personali puoi scrivere al Titolare del trattamento: TopGym personal studio S.s.d.r.l. (P. IVA 03503830543), con sede legale in via Risorgimento n. 17 – 06012, Città di Castello (PG) e sede operativa in via Luca della Robbia 63  – 06012, Città di Castello (PG), in persona del legale rappresentante p.t. Stefano Scudero, (C.F. SCDSFN87H11E716Q), email topgymstudio@gmail.com, pec topgymstudio@pec.it.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" text @click="privacyDialog = false">Chiudi</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="contractDialog" fullscreen dark class="secondary" hide-overlay transition="dialog-bottom-transition" scrollable  >
        <v-card tile>
          <v-card-title class="headline fw-1 text-uppercase accent--text">CONDIZIONI GENERALI DI VENDITA E TERMINI DI USO</v-card-title>
          <v-card-text class="white--text">
            <p>Le presenti condizioni generali di vendita e termini di uso della web app Top Up Your Personal Gym (di seguito le “Condizioni generali”) disciplinano la compravendita tra TopGym personal studio S.s.d.r.l. (di seguito “TopGym” e/o “Venditore”) e la persona fisica e il cliente (di seguito il “Cliente” e/o l’“Acquirente” e/o l’“Utente”) che rivesta la qualità di consumatore ai sensi del Decreto Legislativo 6 settembre 2005, n. 206 (di seguito il “Codice del Consumo”), di contenuti multimediali (di seguito i “Contenuti”) fruibili in streaming da remoto aventi per oggetto una o più sessioni di allenamento (di seguito i “Servizi”), commercializzati  dal Venditore sul sito https://topupgym.pro/ tramite la webapp ivi disponibile (di seguito la “App”).</p>
            <p>L’Acquirente interessato all'acquisto è invitato a leggere attentamente le presenti Condizioni generali prima di acquistare i Servizi. L’accesso e l’utilizzo del sito web www.https://topupgym.pro/ (“Sito”) e della App presuppongono la lettura e la conoscenza dei termini e condizioni di seguito riportati che, unitamente a qualsiasi altra pagina o documento ivi richiamato, costituiscono i termini e condizioni (congiuntamente, le “Termini e Condizioni”) alle quali TopGym consente l’utilizzo dei Sito e della App.</p>
            <p>Le presenti Condizioni generali, nei limiti di utilizzo dei relativi Servizi non modificano, bensì integrano, eventuali altre condizioni o termini in vigore tra gli stessi e TopGym, quali ad esempio le condizioni di associazione del Cliente a TopGym.</p>
            <p>Indice dei contenuti:</p>
            <ol>
              <li>PREMESSA</li>
              <li>INFORMAZIONI RELATIVE A TOPGYM</li>
              <li>CONTENUTI DELLA APP</li>
              <li>CONCLUSIONE DEL CONTRATTO</li>
              <li>GESTIONE DELLA PASSWORD</li>
              <li>CONDIZIONI E RESTRIZIONI DEI SERVIZI</li>
              <li>EROGAZIONE DEL SERVIZIO</li>
              <li>PREZZI E MODALITA’ DI PAGAMENTO </li>
              <li>RESPONSABILITA’ DEL VENDITORE</li>
              <li>DIRITTO DI RECESSO</li>
              <li>LEGGE APPLICABILE E FORO COMPETENTE</li>
              <li>MODIFICHE</li>
              <li>MISCELLANEA</li>
            </ol>
            <h3 class="accent--text mt-3" >1. PREMESSA</h3>
            <p>La vendita dei Servizi tramite App costituisce un contratto a distanza disciplinato dal Capo I, Titolo III, artt. 45 e ss. del Codice del Consumo nonché dal Decreto Legislativo 9 aprile 2003, n. 70, contenente la disciplina del commercio elettronico. Le presenti Condizioni generali non si applicano a quegli Utenti che rivestono la qualità di associati di TopGym, per i quali si rimanda alle specifiche condizioni e termini in vigore tra gli stessi e il Venditore.</p>
            <h3 class="accent--text mt-3" >2. INFORMAZIONI RELATIVE A TOPGYM</h3>
            <p>TopGym personal studio S.s.d.r.l. (P. IVA 03503830543), ha sede legale in via Risorgimento n. 17, Città di Castello (Pg) e sede operativa Piazzale Kennedy n. 1/B, Città di Castello (Pg). I contatti telematici di TopGym sono: topgymstudio@gmail.com e pec topgymstudio@pec.it.</p>
            <h3 class="accent--text mt-3" >3. CONTENUTI DELLA APP</h3>
            <p>Con l’utilizzo della App l'Utente potrà usufruire dei Contenuti ivi presenti costituiti da video rappresentanti una o più sessioni di allenamento. Nello specifico l'Utente potrà utilizzare varie funzionalità, tra cui a titolo esemplificativo: usufruire dei Contenuti del Servizio acquistato senza limitazioni di tempo; ricevere le news TopGym; controllare il proprio programma di allenamento del giorno; verificare il completamento della sessione di allenamento; lasciare al termine di ogni sessione di allenamento un feedback volto al miglioramento del Servizio.</p>
            <h3 class="accent--text mt-3" >4. CONCLUSIONE DEL CONTRATTO</h3>
            <p>L'acquisto dei Servizi avviene previa creazione gratuita da parte dell’Utente di apposito account. Per creare l’account l’Utente deve compilare l'apposito modulo informatico, inserendo nome, cognome, altezza, peso, frequenza di allenamento settimanale, indirizzo di residenza, codice fiscale (solo se residente in Umbria), telefono, data di nascita e una password e cliccare su "registrati". L’avvenuta registrazione sarà confermata all'utente tramite una apposita e-mail. Completata la creazione dell’account l’Utente potrà procedere con l'ordine di acquisto del Servizio nella App. Con l'invio dell'ordine di acquisto, l’Acquirente dà atto di avere letto, compreso e accettato tutti i Termini e Condizioni. </p>
            <p>L’accettazione dell’ordine di acquisto da parte del Venditore avviene mediante l'invio di un messaggio di conferma d'ordine del Venditore all'indirizzo e-mail che l’Acquirente dovrà indicare durante la procedura di creazione dell’account, restando inteso che il predetto mezzo di comunicazione è stato espressamente accettato dall’Acquirente per lo scambio di informazioni rilevanti per la conclusione e l'esecuzione del contratto di vendita. Il Contenuto del Servizio acquistato dall’Acquirente sarà reso disponibile accedendo al proprio account personale della App.</p>

            <h3 class="accent--text mt-3" >5. GESTIONE DELL’ACCOUNT</h3>
            <p>L’Utente è responsabile di ogni attività che si svolge tramite l’account. Per mantenere il controllo dell’account e impedire a chiunque di accedervi (e quindi anche a informazioni strettamente personali e di carattere particolare), il titolare dell’account non dovrà comunicare a nessuno la password o i dettagli del metodo di pagamento associato allo stesso. È responsabilità dell’utente aggiornare le informazioni che vengono fornite in merito al Servizio e mantenerle aggiornate e accurate. Il Venditore si riserva la facoltà di chiudere o sospendere l’accesso al Servizio al fine di proteggere l’utente, TopGym, i partner di TopGym o qualunque terzo da furti d’identità o altre attività fraudolente.<p>
            <p>La gestione dell'account attraverso il quale vengono resi disponibili i Servizi avverrà in base a quanto riportato nella privacy policy che sarà sottoposta all’utente per l'accettazione prima dell’acquisto del Servizio.</p>
            <h3 class="accent--text mt-3" >6. CONDIZIONI E RESTRIZIONI DEI SERVIZI</h3>
            <p>Per acquistare i Servizi occorre aver raggiunto la maggiore età. I minorenni possono accedere ai Servizi solo sotto la supervisione di un adulto. I Contenuti possono essere fruiti solo da soggetti che godono di sana e robusta costituzione. TopGym è esonerata da qualunque responsabilità per danni dipendenti dallo stato di salute dell’utilizzatore. TopGym non svolge attività medica e non è responsabile della verifica dello stato di salute dell’utilizzatore. </p>
            <p>I Servizi sono destinati esclusivamente ad uso personale e non commerciale e rivolti ad acquirenti che rivestono la qualifica di consumatore e non possono essere condivisi fuori dall’ambiente domestico. L’abbonamento conferisce esclusivamente un diritto limitato di accesso, non esclusivo e non trasferibile, ai Servizi. Salvo questo, nessun altro diritto, titolo o interesse viene trasferito in virtù di queste Condizioni. Il Servizio non potrà essere utilizzato in pubblico.</p>
            <p>La fruizione dei Contenuti è consentita solo in conformità alle leggi, norme e regolamenti applicabili e a qualsiasi altra limitazione all’utilizzo del Servizio o al Contenuto. I Contenuti e qualunque materiale acquisiti o comunque fruiti attraverso la App non possono essere riprodotti, distribuiti, modificati, mostrati, pubblicati, concessi in licenza, oppure usati per generare opere derivate da offrire in vendita o utilizzare. </p>
            <p>Le protezioni dei Contenuti non possono essere aggirate, rimosse, alterate, disattivate, degradate o bloccate; è vietato utilizzare robot, spider, scraper o altri strumenti automatici per accedere ai Contenuti e/o al relativo servizio; è vietato decompilare, sottoporre a reverse engineering o disassemblare qualsiasi software o altro prodotto/opera/processo accessibile attraverso il servizio che rende disponibili i Contenuti; è vietato inserire qualsiasi codice o prodotto o manipolare i Contenuti o utilizzare qualsiasi metodo di data mining o di raccolta o estrazione di dati. </p>
            <p>È assolutamente vietato caricare, postare, inviare tramite posta elettronica o altrimenti inviare o trasmettere qualsiasi materiale progettato per interrompere, distruggere o limitare le funzionalità di qualsiasi software o hardware o terminale di telecomunicazione associato ai Contenuti e/o al servizio, inclusi virus e qualsiasi altro codice, file o programma. TopGym può limitare o interrompere l’utilizzo dei contenuti, allorché fossero violate le presenti Condizioni di utilizzo o il servizio venisse utilizzato in maniera illecita o fraudolenta.</p>
            <p>La qualità della visualizzazione dei Contenuti potrebbe essere influenzata da una serie di fattori, quali la posizione geografica, la larghezza della banda disponibile o la velocità della connessione a Internet. Tutti i costi di accesso alla rete Internet saranno a carico dell’utilizzatore. Invitiamo a verificare eventuali addebiti per il consumo della rete dati con il proprio Internet service provider. Il tempo necessario per iniziare a visualizzare un contenuto può variare in base a una serie di fattori, tra cui la posizione geografica, la larghezza di banda disponibile e il contenuto selezionato.</p>
            <p>Il software che rende disponibili i Contenuti è sviluppato da o per conto di TopGym ed è progettato per consentire la visione dei contenuti attraverso i dispositivi compatibili. Tale software potrebbe variare a seconda del dispositivo e le funzionalità e funzioni potrebbero essere diverse a seconda del dispositivo. È altresì possibile che la fruizione dei Contenuti richieda l’utilizzo di software di terze parti soggetto a termini di licenza fissati da terze parti. </p>
            <h3 class="accent--text mt-3" >7. EROGAZIONE DEL SERVIZIO</h3>
            <p>L’Acquirente potrà usufruire sin da subito e senza limitazione alcuna a tutti i Contenuti del Servizio acquistato, erogati in modalità “streaming”, tramite accesso all’account personale della App. La natura dei Contenuti erogati è specificata nella descrizione commerciale del Servizio acquistato.</p>
            <p>Per l’Acquirente residente in Umbria l’erogazione del Servizio è subordinata al preventivo invio a TopGym di specifico certificato medico di sana e robusta costituzione valido per l’anno in corso. L’invio del certificato medico determinerà per l’Acquirente la costituzione di vincolo associativo con TopGym per l’anno in corso.</p>
            <p>L'attivazione e il mantenimento di un account attraverso la quale il Venditore o i suoi fornitori erogano i Servizi da remoto non dà diritto a ricevere gratuitamente gli aggiornamenti o gli upgrades dei contenuti e/o delle applicazioni già attive.</p>
            <h3 class="accent--text mt-3" >8. PREZZI E MODALITA’ DI PAGAMENTO</h3>
            <p>I prezzi per l’acquisto dei Servizi in vendita sulla App s’intendono comprensivi di IVA. Al momento dell’acquisto del Servizio, l’Acquirente deve indicare uno o più “metodi di pagamento”. </p>
            <p>Per “metodo di pagamento” si intende un metodo di pagamento valido e accettato dalla Piattaforma. I metodi di pagamento disponibili potrebbero essere aggiornati anche in base ai dati e alle disposizioni dei gestori dei servizi di pagamento. Per alcuni metodi di pagamento, l’emittente potrebbe addebitare delle commissioni. Gli oneri fiscali addebitati potrebbero variare a seconda del metodo di pagamento utilizzato. </p>
            <p>Per maggiori dettagli occorre verificare con il gestore del servizio legato al metodo di pagamento opzionato.</p>
            <h3 class="accent--text mt-3" >9. RESPONSABILITA’ DEL VENDITORE</h3>
            <p>I Servizi vengono erogati in considerazione delle condizioni psico-fisiche di volta in volta comunicate dall’Acquirente e/o dai fruitori finali dei Servizi stessi. Pertanto l’Acquirente si impegna a far controllare preventivamente e a fare in modo che i fruitori finali dei Servizi controllino preventivamente il proprio stato di salute e a presentare, e/o a fare in modo che i fruitori finali dei Servizi presentino, un certificato medico di sana e robusta costituzione valido per l’anno in corso ed esonera espressamente il Venditore da qualunque responsabilità per danni dipendenti dal proprio stato di salute e/o da quello dei fruitori finali dei Servizi. </p>
            <p>Il Venditore potrà sospendere l’erogazione dei Servizi nel caso in cui si riscontri che l’Acquirente e/o i fruitori finali dei servizi non godono di uno stato di salute adeguato all’attività fisica prevista o, comunque, non abbiano comunicato eventuali variazioni del proprio stato di salute.</p>
            <p>Il Venditore non svolge attività medica e non è in ogni caso responsabile della verifica dello stato di salute dei predetti soggetti. La verifica delle condizioni di salute dell’Acquirente e/o dei fruitori finali dei Servizi è riservata al medico di fiducia scelto dall’Acquirente stesso e/o dai fruitoti finali del servizio o comunque a coloro che esercitano legittimamente la professione sanitaria.</p>
            <h3 class="accent--text mt-3" >10. DIRITTO DI RECESSO </h3>
            <p>Entro 14 (quattordici) giorni lavorativi dalla data di conclusione del contratto l’Acquirente potrà recedere dal contratto senza alcuna penalità e senza specificarne il motivo. </p>
            <p>Il diritto di recesso può essere esercitato esclusivamente dagli Acquirenti che rivestono la posizione di consumatore ai sensi della rilevante normativa, ossia da persone fisiche che abbiano acquistato i prodotti per uso proprio e per finalità non riferibili alla loro attività professionale. Il periodo di recesso scade dopo 14 (quattordici) giorni dalla data di conclusione del contratto. </p>


            <p>La fruizione anche solo parziale del Contenuto da parte dell’Utente così come registrata e visibile nell’account personale della App, determina la perdita del diritto di esercitare il recesso dal contratto. </p>
            <p>Per esercitare il diritto di recesso, l’Acquirente è tenuto a informare TopGym, all’indirizzo e-mail topgymstudio@gmail.com, ovvero pec topgymstudio@pec.it, della decisione di recedere dal contratto tramite una dichiarazione esplicita, ovvero tramite lettera racc. a/r. </p>
            <p>Nel caso in cui il Cliente scegliesse detta opzione, il Venditore trasmetterà senza indugio una conferma di ricevimento del recesso su supporto durevole (ad esempio per posta elettronica). Per rispettare il termine di recesso è sufficiente che l’Acquirente invii la comunicazione relativa all'esercizio del diritto di recesso prima della scadenza del periodo di recesso. Qualora l’Acquirente decida di recedere dal contratto, saranno rimborsati al cliente tutti i pagamenti effettuati in favore del Venditore. </p>
            <p>Detti rimborsi saranno effettuati utilizzando lo stesso mezzo di pagamento usato dall’Acquirente per la transazione iniziale, salvo che l’Acquirente non abbia espressamente convenuto altrimenti; in ogni caso l’Acquirente non dovrà sostenere alcun costo quale conseguenza di tale rimborso. Nei limiti consentiti dalla legge, i pagamenti non sono rimborsabili e non vengono riconosciuti rimborsi o crediti per utilizzi parziali o Contenuti non fruiti. </p>

            <h3 class="accent--text mt-3" >11. LEGGE APPLICABILE E FORO COMPETENTE</h3>
            <p>Le presenti Condizioni generali sono regolate dalla legge italiana. Per ogni eventuale controversia tra l’Acquirente e il Venditore riferita alle presenti Condizioni generali sarà competente il Foro di residenza o domicilio dell’Acquirente.</p>
            <h3 class="accent--text mt-3" >12. MODIFICHE</h3>
            <p>Le Condizioni generali possono essere modificate in ogni momento. Eventuali modifiche e/o nuove condizioni saranno in vigore dal momento della loro pubblicazione sul sito.</p>
            <p>Gli Acquirenti sono invitati ad accedere con regolarità al sito e a consultare, prima di effettuare qualsiasi acquisto, la versione più aggiornata delle Condizioni generali.</p>
            <h3 class="accent--text mt-3" >13. MISCELLANEA</h3>
            <p>Qualora una disposizione del presente contratto sia ritenuta illegittima, nulla o non efficace per qualsiasi motivo, tale disposizione sarà considerata scindibile dalle altre disposizioni del presente contratto e non altererà in alcun modo la validità ed efficacia delle restanti disposizioni del contratto.</p>
            <p>Nelle presenti Condizioni generali del contratto la forma singolare include quella plurale e viceversa in ragione dello specifico contesto.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" text @click="contractDialog = false">Chiudi</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="alertDialog" persistent max-width="350" dark class="secondary">
        <v-card>
          <v-card-title class="headline fw-1 text-uppercase accent--text">{{alertTitle}}</v-card-title>
          <v-card-text>
            <p class="body-1" v-html="alertMessage"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" text @click="onAlertCallback()">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="notLoggedDialog" persistent max-width="500" class="secondary" dark>
        <v-card >
          <v-card-title class="headline fw-1 text-uppercase accent--text">Oops!</v-card-title>
          <v-card-text>
            <p class="body-1">Per poterti allenare con noi devi accedere al tuo account. Non lo hai? Nessun problema! Puoi crearne uno nuovo registrandoti, basta solo un pochino del tuo tempo.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="notLoggedDialog = false">Annulla</v-btn>
            <v-btn color="accent" text @click="goToRegisterPage()">Registrati</v-btn>
            <v-btn color="accent" text @click="goToLoginPage()">Accedi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="loading" persistent fullscreen hide-overlay dark>

        <v-container fluid class="fill-height d-flex align-center justify-center secondary">
          <v-img class="sfondo" style="position: fixed; width: 100%; height: 100%;" :src="bg" />
          <v-row>
            <v-col cols="12" class="d-flex align-center justify-center text-center">
              <v-progress-circular class="d-flex" indeterminate color="primary" size="60"></v-progress-circular>

            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center text-center" v-if="loadingMessage">
              <h3 style="text-align: center;" class="title white--text">{{loadingMessage}}</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>

      <v-snackbar
              style="border-radius: 24px"
              dark
              :timeout="5000"
              :color="snackbarColor"
              top
              multi-line
              v-model="snackbar">
        {{dialogMessage}}
      </v-snackbar>

      <v-footer v-if="$route.name === 'home'" dark class="d-flex justify-center" fixed>
        <span class="text-uppercase text-center" style="font-size: 0.625rem">Top Gym Studio, Via Luca della Robbia 63 - Centro Commerciale "Le Fonti" - Città di Castello</span>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-btn text x-small color="accent" @click="contractDialog = true">Termini e condizioni</v-btn>
        <v-btn text x-small color="accent" @click="privacyDialog = true">Privacy policy</v-btn>

        <span class="text-uppercase ml-2" style="font-size: 0.625rem">Whatsapp per info <a href="tel:+393662680364">+393662680364</a> (lun-ven 7-22, sab 8-17)</span>
      </v-footer>
    </v-main>


  </v-app>
</template>

<script>



  import {mapGetters, mapActions, mapState } from 'vuex'
  import config from './config'
  import moment from "moment";
  export default {
    name: 'App',
    data () {
      return {
        version : config.version,
        advMode : config.develomentPage,
        drawer : false,
        offsetTop : 0
      }
    },
    computed : {
      ...mapState(['confirmDialogTitle','confirmDialogCallback', 'dialogMessage', 'loadingMessage']),
      ...mapGetters(['token','profile','is_admin','is_editor','confirmDialogMessage','isAndroid',
        'bgImage','onAlertCallback','alertTitle','alertMessage','purchases','numItemsInCart','appBarStyle','banners','settings']),

      bannerDuration() {
        return this.settings.banner_duration ? this.settings.banner_duration * 1000 : 6000
      },
      numItemsInCartLabel() {
        return this.numItemsInCart ? this.numItemsInCart : "0";
      },
      isShowBack() {
        return this.$route.name !== 'home' && this.$route.name !== 'development';
      },
      isAppBarRoute() {
        return this.$route.name !== 'intro' && this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'lesson' && this.$route.name !== 'lesson alt' && this.$route.name !== 'work';
      },
      isVideoBg() {
        return this.$route.name === 'lesson' || this.$route.name === 'work';
      },
      bannerList() {
        let bann = this.banners.filter(b => {
          let exp = b.exp ? moment(b.exp) : null;
          if(exp && exp.isBefore()) {
            return false
          }
          return b.pub;
        })
        bann.sort((a, b) => {
          return b.priority - a.priority;
        })
        return bann;
      },
      pages() {
        let items = [
          {title : "Home", icon : "mdi-home-variant-outline", link : "/"}
        ];
        if(!config.hideFilterPage) {
          items.push({title : "Allenamenti", icon : "mdi-magnify", link : "/products"})
        }
        if(this.purchases && this.purchases.length) {
          items.push({title : "I miei acquisti", icon : "mdi-dumbbell", link : "/mytrainings"})
        }
        items.push({title : "Carrello", icon : "mdi-cart-outline", link : "/cart"})
        items.push({title : "Account", icon : "mdi-account-circle-outline", link : "/account"})
        items.push({title : "Chi siamo", icon : "mdi-head-question-outline", link : "/contacts"})
        items.push({title : "Spot", icon : "mdi-bullhorn-outline", link : "/intro"})
        return items
      },
      bg() {
        console.log(this.bgImage)
        return this.bgImage;
      },
      loggedIn() {
        return this.profile != null
      },
      initialized : {
        set(newVal) {  this.$store.state.initialized = newVal },
        get() { return this.$store.state.initialized }
      },
      privacyDialog : {
        set(newVal) {  this.$store.state.privacyDialog = newVal },
        get() { return this.$store.state.privacyDialog }
      },
      contractDialog : {
        set(newVal) {  this.$store.state.contractDialog = newVal },
        get() { return this.$store.state.contractDialog }
      },
      orientation : {
        set(newVal) {  this.$store.state.orientation = newVal },
        get() { return this.$store.state.orientation }
      },
      loading : {
        set(newVal) {  this.$store.state.loading = newVal },
        get() { return this.$store.state.loading }
      },
      notLoggedDialog: {
        set(newVal) {  this.$store.state.notLoggedDialog = newVal },
        get() { return this.$store.state.notLoggedDialog }
      },
      loginDialog : {
        get() { return this.$store.state.loginDialog },
        set(newVal) {  this.$store.state.loginDialog = newVal }
      },
      alertDialog : {
        get() { return this.$store.state.alertDialog },
        set(newVal) {  this.$store.state.alertDialog = newVal }
      },
      successDialog : {
        get() { return this.$store.state.successDialog },
        set(newVal) {  this.$store.state.successDialog = newVal }
      },
      warningDialog : {
        get() { return this.$store.state.warningDialog },
        set(newVal) {  this.$store.state.warningDialog = newVal }
      },
      errorDialog : {
        get() { return this.$store.state.errorDialog },
        set(newVal) {  this.$store.state.errorDialog = newVal }
      },
      infoDialog : {
        get() { return this.$store.state.infoDialog },
        set(newVal) {  this.$store.state.infoDialog = newVal }
      },
      confirmDialog : {
        get() { return this.$store.state.confirmDialog },
        set(newVal) {  this.$store.state.confirmDialog = newVal }
      },
      snackbar : {
        get() {
          return this.successDialog || this.warningDialog || this.errorDialog || this.infoDialog;
        },
        set(newVal) {
          this.successDialog = newVal;
          this.warningDialog = newVal;
          this.errorDialog = newVal;
          this.infoDialog = newVal;
        }
      },
      snackbarColor() {
        if(this.successDialog) return 'success';
        if(this.errorDialog) return 'error';
        if(this.warningDialog) return 'warning';
        return undefined;
      },
    },
    methods: {
      ...mapActions(['login', 'newCart', 'logout', 'loadProfile','loadSettings', 'loadPurchases', 'loadUserCart', 'setToken', 'setProfile',
        'sendError','onScroll','requestConfirm','setOngoingWorkout','refreshCollection']),
      goHome() {
        if(this.$route.name === 'home') return
        this.$router.push('/')
      },
      goToCart() {
        if(this.$route.name === 'cart') return
        this.$router.push('/cart')
      },
      onConfirmDialogExit( positive ) {
        this.confirmDialogCallback(positive);
        this.confirmDialog = false
      },
      goToRegisterPage() {
        this.notLoggedDialog = false;
        this.$router.push("/register")

      },
      goToLoginPage() {
        this.notLoggedDialog = false;
        this.$router.push('/login')
      },
      firstInteraction(e) {
        window.removeEventListener('touchstart', this.firstInteraction);
        window.removeEventListener('mousedown', this.firstInteraction);
        this.$store.state.userInteracted = true;
      },
      openBanner(b) {
        if(!b.link) return;
        window.open(b.link, '_blank')
      }
    },
    mounted(){
      moment.locale('it')
      if(this.$route.query.t === 'iamthedeveloper') {
        this.advMode = false
      }


      this.loadSettings()
      if(this.token) {
        console.log(this.token)
        this.loadProfile().then(() => {
          this.loadPurchases().then(() => {
            this.loadUserCart().then(()=>{
              this.initialized = true;
            }).catch((err) => {
              if(config.debug) console.error(err);
              this.newCart();
              this.initialized = true;
            })
          }).catch((err) => {
            if(config.debug) console.error(err);
            this.initialized = true;
          })
          let resume = localStorage.resume;
          if(resume && this.$route.name !== 'work') {
            let [purchase_id, lesson_id, schedule] = resume.split('|')
            if(purchase_id && lesson_id && schedule) {
              this.requestConfirm({title:"Riprendi allenamento", message:"Hai un allenamento in sospeso vuoi riprendere da dove avevi lasciato?", callback:(confirmed)=>{
                  if(confirmed)
                    this.$router.push(`/trainings/${purchase_id}/${lesson_id}/work`)
                  else
                    this.setOngoingWorkout({purchase_id:null, lesson_id: null, schedule: null})
                }})

            }
          }
        }).catch((err) => {
          if(config.debug) console.error(err);
          this.initialized = true;
        })
      } else {
        this.initialized = true;
      }


      this.orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portraits';
      setInterval(() => {
        this.orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portraits';
      }, 1000);

      window.addEventListener('scroll', (e) => {
        this.onScroll({offsetTop: window.scrollY})
      });

      window.addEventListener('touchstart', this.firstInteraction);
      window.addEventListener('mousedown', this.firstInteraction);

      this.refreshCollection({collection: 'cms'});
      this.refreshCollection({collection: 'banners'});
      this.refreshCollection({collection:'gallery',sort:'priority', order:'desc'})
      this.loadSettings()
    }
  };
</script>
