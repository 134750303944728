/*
<v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('topgymexperience')">TopGym Experience</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('opengym')">Open Gym</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('corsi')">Sala Corsi</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('onetoone')">One-to-One</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('gym')">Ginnastica e Corpo Libero</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('vacu')">Vacu</v-chip>
 */

const seatsTypes = {
    topgymexperience : 'topgymexperience',
    opengym : 'opengym',
    corsi : 'corsi',
    gym : 'gym',
    vacu : 'vacu',
}

const seatsTypesOptions = [
    { text : 'Top Gym Experience', abbrev : 'Top Gym Exp.', value : seatsTypes.topgymexperience, color : '#F4511E'},
    { text : 'Open Gym', abbrev : 'Open Gym', value : seatsTypes.opengym, color: '#7CB342'},
    { text : 'Sala Corsi', abbrev : 'Sala Corsi', value : seatsTypes.corsi, color: '#1E88E5'},
    { text : 'Bike indoor', abbrev : 'Bike', value : seatsTypes.gym, color: '#673AB7'},
    { text : 'Vacu', abbrev : 'Vacu', value : seatsTypes.vacu, color: '#E91E63'},
]



export { seatsTypes, seatsTypesOptions }