<template>
  <v-container fluid class="fill-height" dark >
    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0">
        <v-form @submit="onSubmit">
          <v-row>
            <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
              <v-img class="flex-grow-0" alt="Top Up Your Personal Gym Logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" v-if="step <= 3">VUOI MANDARCI UN REPORT?</h2>
            </v-col>
            <v-stepper v-model="step" vertical dark style="background: transparent" class="full-width elevation-0"  v-if="step <= 3">
              <template >
                <template v-for="n in steps" >
                  <v-stepper-step :key="`${n}-step`" :complete="step > n" :step="n" editable>
                    {{stepName(n)}}
                  </v-stepper-step>

                  <v-stepper-content :key="`${n}-content`" :step="n">
                    <v-row>
                      <v-col cols="12" v-if="n===1">
                        <p class="white--text no-select text-justify" >Questo report è composto da un breve questionario sull'andamento dei tuoi allenamenti, in questo modo possiamo aiutarti, studiando il tuo caso personale e dandoti i nostri consigli professionali. Se necessario, quindi, ti potremmo ricontattare per telefono o per email.</p>
                      </v-col>

                      <v-col cols="12" v-if="n===1">
                        <p class="no-select white--text subtitle-1">Quante volte a settimana ti alleni in media?</p>
                        <v-select
                                dark
                                label="N° allenamenti*"
                                rounded filled
                                persistent-hint
                                hint="a settimana"
                                :items="trainingOptions"
                                v-model="editingItem.training"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" v-if="n===1">
                        <v-text-field
                                label="Peso (kg)*"
                                rounded filled
                                type="number"
                                :step="1"
                                v-model="editingItem.weight"
                                hide-details
                                @input="weightError = false"
                                :error="weightError"
                                :error-messages="requiredErrorMessages(weightError)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="n===1">
                        <p class="no-select white--text subtitle-1">Quanta fatica hai percepito in questo allenamento in una scala da 1 a 5?</p>
                        <v-rating v-model="editingItem.borg" color="accent" length="5"/>
                      </v-col>



                      <!-- STEP 2 --->
                      <v-col cols="12" v-if="n===2">
                        <p class="no-select white--text subtitle-1">Rispetto agli allenamenti già svolti hai percepito molta fatica?</p>
                        <v-radio-group v-model="editingItem.perceived_fatigue" row>
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                        <v-textarea label="Note" v-model="editingItem.perceived_fatigue_notes" rounded filled hide-details></v-textarea>
                      </v-col>

                      <v-col cols="12" v-if="n===2">
                        <p class="no-select white--text subtitle-1">Hai percepito più fatica durante lo svolgimento delle prime lezioni rispetto alle ultime?</p>
                        <v-radio-group v-model="editingItem.perceived_fatigue_progress" row>
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                        <v-textarea label="Note" v-model="editingItem.perceived_fatigue_progress_notes" rounded filled hide-details></v-textarea>
                      </v-col>

                      <v-col cols="12" v-if="n===2">
                        <p class="no-select white--text subtitle-1">Hai percepito la stessa fatica durante tutte le lezioni?</p>
                        <v-radio-group v-model="editingItem.perceived_fatigue_all" row>
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                        <v-textarea label="Note" v-model="editingItem.perceived_fatigue_all_notes" rounded filled hide-details></v-textarea>
                      </v-col>

                      <v-col cols="12" v-if="n===2">
                        <p class="no-select white--text subtitle-1">Pensi di essere pronto a cambiare categoria e livello di allenamento?</p>
                        <v-radio-group v-model="editingItem.training_change_question" row>
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                        <v-textarea label="Note" v-model="editingItem.training_change_question_notes" rounded filled hide-details></v-textarea>
                      </v-col>

                      <!-- STEP 3 -->

                      <v-col cols="12" v-if="n===3">
                        <p class="no-select white--text subtitle-1">Ci sono esercizi che non hai capito?</p>
                        <v-radio-group v-model="editingItem.trainings_not_understood" row @change="onTrainingNotUnderstoodChange">
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" v-if="n===3 && editingItem.trainings_not_understood">
                        <p class="no-select white--text subtitle-1">Quali?</p>
                        <v-checkbox v-for="exercise in exercises" :key="`${exercise._id}-trainings_not_understood`"
                                    :label="exercise.title.it" :value="exercise._id" v-model="editingItem.trainings_not_understood_list"></v-checkbox>
                      </v-col>

                      <v-col cols="12" v-if="n===3">
                        <p class="no-select white--text subtitle-1">Ci sono esercizi che non riesci a svolgere?</p>
                        <v-radio-group v-model="editingItem.trainings_failed" row>
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" v-if="n===3 && editingItem.trainings_failed">
                        <p class="no-select white--text subtitle-1">Quali?</p>
                        <v-checkbox v-for="exercise in exercises" :key="`${exercise._id}-trainings_failed`"
                                    :label="exercise.title.it" :value="exercise._id" v-model="editingItem.trainings_failed_list"></v-checkbox>
                      </v-col>

                      <v-col cols="12" v-if="n===3">
                        <p class="no-select white--text subtitle-1">Riesci sempre a rimanere nei tempi dei singoli esercizi?</p>
                        <v-radio-group v-model="editingItem.training_in_time" row>
                          <v-radio label="Sì" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                        <v-textarea label="Note" v-model="editingItem.training_in_time_notes" rounded filled hide-details></v-textarea>
                      </v-col>



                      <v-col cols="12" v-if="n===3">
                        <p class="no-select white--text subtitle-1">Come ti senti da quando hai iniziato ad allenarti?</p>
                        <v-radio-group v-model="editingItem.feelings" row>
                          <v-radio label="Normale" :value="2"></v-radio>
                          <v-radio label="Male" :value="1"></v-radio>
                          <v-radio label="Bene" :value="3"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" v-if="n===3">
                        <p class="no-select white--text subtitle-1">Vuoi dirci qualcos'altro?</p>
                        <v-textarea label="" v-model="editingItem.notes" rounded filled hide-details></v-textarea>
                      </v-col>

                      <v-col cols="12" class="d-flex justify-end">
                        <span class="body-2 white--text no-select">* campi richiesti</span>
                      </v-col>
                      <v-col cols="12"  class="d-flex justify-end">
                        <v-btn class="ml-3" color="accent" rounded @click="skip()" v-if="step == 1">Salta report</v-btn>
                        <v-btn class="ml-3" color="accent" rounded @click="goBack()" v-if="step > 1">Indietro</v-btn>
                        <v-btn class="ml-3" color="accent" rounded @click="goNext()" v-if="step < 3">Continua</v-btn>
                        <v-btn class="ml-3" color="accent" type="submit" rounded v-if="step === 3" >Invia report</v-btn>
                      </v-col>
                    </v-row>

                  </v-stepper-content>
                </template>
              </template>
            </v-stepper>
          </v-row>
        </v-form>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import utils from "../utils";
  import config from "../config";
  import moment from 'moment'
  export default {
    name: "Report",
    data() {
      return {
        step : 1,
        steps : 3,
        product : null,
        lessons : [],
        editingItem: {
          user_id: null,
          kit_id: null,
          product_id: null,
          lesson_id: null,
          date : new Date(),
          weight : 0,
          training : 1,
          borg : 1,
          perceived_fatigue: false,
          perceived_fatigue_notes: "",
          perceived_fatigue_progress: false,
          perceived_fatigue_progress_notes: "",
          perceived_fatigue_all: false,
          perceived_fatigue_all_notes: "",
          training_change_question: false,
          training_change_question_notes: "",
          trainings_not_understood: false,
          trainings_not_understood_list : [],
          trainings_failed : false,
          trainings_failed_list : [],
          training_in_time : false,
          training_in_time_notes : "",
          feelings : 3,
          notes : "",
        },
        feelingsOptions: [
          { text : "Male", value : 1},
          { text : "Normale", value : 2},
          { text : "Molto bene", value : 3},
        ],
        trainingOptions : [
          { text : "Meno di 1 giorno", value : 0},
          { text : "1 giorno", value : 1},
          { text : "2 giorni", value : 2},
          { text : "3 giorni", value : 3},
          { text : "4 giorni", value : 4},
          { text : "5 giorni", value : 5},
          { text : "6 giorni", value : 6},
          { text : "7 giorni", value : 7},
        ],
        exercises: [],
        weightError : false,
      };
    },
    computed: {
      ...mapGetters(["token", "profile",'usablePurchases']),
      age() {
        const age = moment().diff(moment(this.profile.date_of_birth), 'years');
        if(_.isNaN(age)) return 0;
        return Math.max(age, 0)
      },
      fcMax() {
        const age = moment(this.profile.date_of_birth).diff(moment(), 'years');
        if(_.isNaN(age)) return 220;
        return 220 - Math.abs(age);
      },
      bmi() {
        const weight = utils.toInt( this.editingItem.weight, 1 )
        const height = utils.toInt( this.profile.height, 100 ) / 100;
        const bmi = (weight / ( height * height ))
        if(_.isNaN(bmi)) return '-'
        return bmi.toFixed(2);
      },
      loading: {
        get() {
          return this.$store.state.loading;
        },
        set(val) {
          this.$store.state.loading = val;
        }
      },
      validStep() {
        let valid = true;
        switch (this.step) {
          case 1:
            this.weightError = false;
            if(!this.editingItem.weight) {
              this.weightError = true;
              valid = false;
            }
            break;
          case 2:
            break;
          case 3:
            break;
        }
        return valid;
      }
    },
    methods: {
      ...mapActions(["get", "sendSuccess",
        "sendError", "requestConfirm", "insert","loadLessons",'loadPurchases',
        'loadProfile', "logout", "alert", "setDone"
      ]),
      stepName( n ) {
        switch ( n ) {
          case 1:
            return "Informazioni generali"
          case 2:
            return "Andamento"
          case 3:
            return "Comprensione e sensazioni"
        }
      },

      requiredErrorMessages(err) {
        if (err) return ["Riempi questo campo!"];
        return [];
      },
      onTrainingNotUnderstoodChange() {
        console.log(">>>", this.editingItem.trainings_not_understood)
        this.$forceUpdate()
      },
      onSubmit(e) {
        e.preventDefault();
        this.onSendReportClick();
      },
      onSendReportClick() {
        this.editingItem.weight = utils.toInt(this.editingItem.weight, 0);
        if(this.editingItem.weight === 0) {
          this.step = 1;
          this.weightError = true
          return;
        }
        this.editingItem.training = utils.toInt(this.editingItem.training, 1);
        this.editingItem.borg = utils.toInt(this.editingItem.borg, 1);
        this.editingItem.feelings = utils.toInt(this.editingItem.feelings, 2);

        this.requestConfirm({ title : 'ATTENZIONE!', message : "Sei sicuro di quello che hai scritto? Informazioni inesatte potrebbero portarci a conclusioni sbagliate e il tuo allenamento futuro ne risentirebbe.",
          callback : (accepted) => {
            if(accepted) {
              this.loading = true;
              this.insert({ collection : 'reports', data : this.editingItem }).then(()=>{
                this.loading = false;
                this.$router.replace('/trainings/' + this.$route.purchase_id);
              }).catch((err) => {
                this.loading = false;
                const message = err.message ? err.message : "Ci scusiamo, c'è stato un errore durante l'invio del report riprova più tardi.";
                this.sendError({message});
              })
            }
          }
        });
      },
      skip() {
        this.requestConfirm({ title : 'ATTENZIONE!', message : "Sei sicuro di non volerci inviare il report?",
          callback : (accepted) => {
            if(accepted) this.$router.replace('/trainings/' + this.$route.params.purchase_id);
          }
        });

      },
      goBack() {
        if(this.step > 1) {
          this.step--;
        }
      },
      goNext() {
        if (this.step === 1 || this.step === 2) {
          if(this.validStep) {
            setTimeout(()=>{
              this.step++
            }, 500)
          }
        }
      },
    },
    mounted() {
      this.loading = true;
      const catcher = (err) => {
        this.loading = false;
        if(config.debug) console.error(err);
        if(err.status === 401) {
          this.logout();
          return
        }
        //this.$router.go(-1);
      }
      this.editingItem.lesson_id = this.$route.params.id;
      this.loadProfile().then(()=>{
        this.editingItem.user_id = this.profile._id;
        this.editingItem.weight = this.profile.weight;
        this.editingItem.training = this.profile.training;
        //${this.$route.params.product_id}/${this.$route.params.id}
        this.loadPurchases().then(()=>{
          let filter = this.usablePurchases.filter((item) => item._id === this.$route.params.purchase_id);
          if(!filter.length) {
            this.notFound();
            return
          }
          this.purchase = filter[0];
          this.setDone({lesson_id:this.$route.params.id, purchase_id:this.$route.params.purchase_id});
          this.get({collection:'products', id : `${this.purchase.product._id}?display=true`}).then((reply) => {
            this.product = reply.data;
            this.editingItem.product_id = reply.data._id;
            this.editingItem.kit_id = reply.data.kit._id;
            this.loadLessons({product_id: this.purchase.product._id}).then((lessons)=> {
              this.lessons = lessons
              this.loading = false;

              for(let i = 0; i < lessons.length; i++) {
                if(lessons[i]._id === this.$route.params.id) {
                  console.log(lessons[i].exercises)
                  this.exercises = lessons[i].exercises;
                }
              }


            }).catch(catcher)
        }).catch(catcher)


        }).catch(catcher);
      }).catch(catcher);
    }
  };
</script>
