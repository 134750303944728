<template>
    <v-container fluid class="fill-height" dark >
        <v-row>
            <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0">
                <v-row v-if="loggedIn">
                    <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
                        <v-img class="flex-grow-0" alt="Top Up Your Personal Gym Logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" >BENVENUTO!</h2>
                    </v-col>
                    <v-col cols="12">
                        <p class="no-select white--text text-center">Ora il tuo account è attivo e possiamo concentrarci sul tuo allenamento!</p>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn rounded color="accent" class="flex-grow-0 flex-shrink-1"  @click="$router.replace('/')">iniziamo!</v-btn>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import config from "../config";
    export default {
        name: "Register",
        data() {
            return {

            };
        },
        computed: {
            ...mapGetters(['profile']),
            loggedIn() {
                return this.profile != null
            },
            loading : {
                set(newVal) {  this.$store.state.loading = newVal },
                get() { return this.$store.state.loading }
            },
        },
        methods: {
            ...mapActions([
                'setToken',
                'setProfile',
                'confirmRegister'
            ]),
        },
        mounted() {
            this.loading = true;
            this.confirmRegister({token: this.$route.params.token }).then((reply)=>{
                if(config.debug) console.log(reply);
                this.setToken({ token : reply.token });
                this.setProfile({ profile : reply.user });
                this.loading = false;
            }).catch((err)=>{
                console.error(err);
                this.loading = false;
                this.$router.replace('/notfound');

            })
        }
    };
</script>
