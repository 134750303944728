<template>
  <div style="width: 100%; height: 100%">
    <video class="full-bg-video" ref="video" :src="item.video.url" autoplay loop playsinline ></video>
    <div id="lesson-content" class="d-flex fill-height full-width align-end justify-center overflow-hidden pa-0 ma-0">

      <v-btn fab color="accent" small top right fixed @click="$router.go(-1)">
        <v-icon>mdi-close</v-icon>
      </v-btn>


      <v-btn color="accent" icon width="150" height="150" class="align-self-center justify-self-center" @click="playVideo()" v-if="isVideoPaused" >
        <v-icon size="150">mdi-play-circle-outline</v-icon>
      </v-btn>

      <v-card v-if="orientation === 'portraits' && !isVideoPaused" dark class="ma-6 pa-4 align-self-center" style="background: rgba(0,0,0,.4)">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-icon x-large color="white">mdi-phone-rotate-landscape</v-icon>
          </v-col>
          <v-col cols="12">
            <p class="title white--text text-center">Per una corretta visualizzazione del video ruota lo schermo in orizzontale.</p>
          </v-col>
        </v-row>
      </v-card>

      <v-sheet id="bottom-lesson-list" dark class="d-flex flex-grow-0 flex-shrink-1 secondary border-rounded justify-self-end" :width="bottomSheetWidth"
               style="position: fixed; margin-bottom: -24px" :max-height="contentMaxHeight" >


        <v-btn rounded color="accent" top right absolute @click="startLesson()">
          Avvia Lezione
          <v-icon>mdi-play</v-icon>
        </v-btn>

        <v-row :class="'pl-6 pr-6 pb-0 pt-1 ' + (contentOpen ? 'overflow-y-auto' : '')">
          <v-col cols="12" class="d-flex justify-center pb-0" v-ripple="{ 'class': 'accent--text' }" @click="toggleContent()">
            <v-icon v-if="contentOpen" color="white">mdi-chevron-down</v-icon>
            <v-icon v-else color="white">mdi-chevron-up</v-icon>
          </v-col>
          <v-col cols="12">
            <h2 class="no-select title accent--text fw-1 text-uppercase">{{item.title.it}}</h2>
          </v-col>
          <v-col cols="12">
            <h2 class="no-select title white--text mb-2">{{item.schedules.length}} sessioni di esercizio</h2>
          </v-col>
          <v-col cols="12">
            <v-divider dark class="mb-2"/>
          </v-col>
          <v-col cols="12">
            <h2 class="no-select title white--text mb-1 fw-1 text-uppercase">Anteprima degli esercizi in questa lezione</h2>
          </v-col>
          <v-col cols="12" style="padding-bottom: 48px;">
            <v-row v-for="(ex, i) in item.exercises" :key="'ex'+i">
              <v-col cols="12"  v-ripple="{ 'class': 'accent--text' }" @click="openExerciseVideo(ex)">
                <h4 class="body-2 accent--text pointer mr-5 d-flex align-center"><v-icon class="mr-2">mdi-chevron-right</v-icon><span class="underline">{{ex.title.it}}</span></h4>
              </v-col>
            </v-row>
          </v-col>


        </v-row>
      </v-sheet>

      <v-dialog :width="exerciseDialogWidth" v-model="exerciseDialog" dark class="border-rounded">
        <v-card dark class="border-rounded">
          <v-row class="ma-0 pa-6">
            <v-col cols="12">
              <h3 class="title white--text">{{exercise.title.it}}</h3>
            </v-col>
            <v-col cols="12">
              <p class="body-1 white--text mb-0">{{exercise.description.it}}</p>
            </v-col>
            <v-col cols="12" >
              <v-responsive :aspect-ratio="16/9">
                <video ref="helpVideo" :src="exerciseVideoUrl" autoplay controls class="full-width fill-height" />
              </v-responsive>
            </v-col>
          </v-row>
          <v-btn absolute top right icon color="accent" @click="exerciseDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card>
      </v-dialog>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'
import anime from 'animejs/lib/anime.es.js';
import config from '../config'
export default {

  name: "Lesson",
  data () {
    return {

      privExerciseDialog : false,
      isVideoPaused : false,
      contentOpen : false,
      contentMaxHeight : '124px',
      item : {
        title : { it : '' },
        product_id : null,
        video : {
          url : ''
        },
        schedules : [],
        exercises : [],
        exercises_video : [],
        exercises_audio : [],
        last_update : new Date(),
      },
      exercise : {
        title : {it : ''},
        description : {it : ''},
        video : {

        }
      },
      lessons : [],
      purchase : null,
      product : {
        title : { it : '' },
        description : { it : '' },
        days_interval : 1,
        sku : '',
        price : 0,
        expedition : false,
        last_update : new Date(),
        kit : {
          title : { it : '' },
          description : { it : '' },
          category : {
            name : { it : '' },
            abbrev : { it : '' },
          },
          genre : {
            name : { it : '' },
            abbrev : { it : '' },
          },
          difficulty : {
            name : { it : '' },
            abbrev : { it : '' },
            value : 0,
          },
          cover : {
            url : ''
          },
          icon : {
            url : ''
          },
          last_update : new Date(),
          pub : false
        },
      }
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','kits','purchases','usablePurchases','products','ongoingLessons','doneLessons','cart','orientation','media','userInteracted']),
    exerciseDialog : {
      set(newVal) {
        if(newVal && this.$refs.helpVideo && this.$refs.helpVideo.paused) {
          this.$refs.helpVideo.play();
        }
        if(!newVal && this.$refs.helpVideo) {
          this.$refs.helpVideo.currentTime = 0;
          this.$refs.helpVideo.pause();
        }
        this.privExerciseDialog = newVal
      },
      get() {
        return this.privExerciseDialog
      }
    },

    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    isOngoing(){
      return this.purchase && this.purchase.ongoing_lessons && this.purchase.ongoing_lessons.includes(this.item._id);
    },
    isDone(){
      return this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(this.item._id);
    },
    bottomSheetWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100%'
        case 'sm': return '100%'
        case 'md': return '66%'
        case 'lg': return '50%'
        case 'xl': return '33%'
      }
    },
    exerciseVideoUrl() {
      if(!this.exercise || !this.exercise.video_id) {
        console.log("no exercise");
        return '';
      }
      const video = this.getVideo(this.exercise.video_id);
      if(!video) return '';
      return video.url;
    },
    exerciseDialogWidth() {
      if(window.innerWidth > window.innerHeight) {
        return "55%";
      }
      return "90%"
    },
    isNextLessonTodo() {
      for(let i = 0; i < this.lessons.length; i++) {
        const current = this.lessons[i]
        if(this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(current._id)) {
          continue;
        }
        if(current._id === this.item._id) {
          if(i > 0) {
            let prev = this.lessons[i-1]
            return this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(prev._id);
          }
          return true
        }
      }
      return false;
    }
  },
  methods : {
    ...mapActions(['load', 'get', 'sendError', 'loadPurchases', 'refreshCollection', 'refreshCollectionPromise',
      'requestConfirm','notFound','loadLesson','loadLessons','setOngoingWorkout']),
    playVideo() {
      this.$refs.video.play();
      this.isVideoPaused = false;
    },
    toggleContent() {
      this.contentOpen = !this.contentOpen;
      let target = '124px';
      if(this.contentOpen) {
        target = (window.innerHeight * 0.75) + 'px';
      }

      anime({
        targets :this,
        contentMaxHeight : target,
        easing : 'easeInQuad',
        duration : 300
      })
    },
    openExerciseVideo( item ) {
      this.exercise = item;
      this.$refs.video.pause();
      this.exerciseDialog = true;
      this.isVideoPaused = true;
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    getVideo(id) {
      for(let i = 0; i < this.item.exercises_video.length; i++) {
        if(this.item.exercises_video[i]._id === id) return this.item.exercises_video[i];
      }
      return null
    },

    lessonClass(id) {
      if(this.isOngoing(id)) {
        return 'title warning--text underline'
      }
      if(this.isDone(id)) {
        return 'title grey--text text--darken-2 fw-1'
      }
      return 'title white--text';
    },
    difficultyColor(difficulty) {
      switch (difficulty.value) {
        case 1:
          return 'success'
        case 2:
          return 'lime'
        case 3:
          return 'warning'
        case 4:
          return 'error'
      }
      return 'grey'
    },
    startLesson() {
      if(this.purchase && this.purchase.done_lessons && this.purchase.done_lessons.includes(this.$route.params.id)) {
        this.sendError({message:'Hai già completato questa lezione!'});
        return
      }
      if(!this.isNextLessonTodo) {
        this.sendError({message:'Non puoi fare questa lezione: devi ancora completare le lezioni precedenti!'});
        return
      }
      this.setOngoingWorkout({purchase_id:null, lesson_id: null, schedule: null})
      this.$router.push(`/trainings/${this.$route.params.purchase_id}/${this.$route.params.id}/work`);
    }
  },
  mounted() {
    this.loading = true;
    const catcher = (err)=>{
      if(config.debug) console.error(err);
      this.loading = false;
      this.$router.go(-1);
    };
    this.loadPurchases().then(()=>{
      let filter = this.usablePurchases.filter((item) => item._id === this.$route.params.purchase_id);
      if(!filter.length) {
        this.notFound();
        return
      }

      this.purchase = filter[0];
      this.refreshCollectionPromise({collection: 'products'}).then(()=>{
        this.loadLessons({product_id: this.purchase.product._id}).then((lessons)=> {
          //console.log("lessons",lessons)
          this.lessons = lessons
        }).catch(catcher)
        this.loadLesson({product_id: this.purchase.product._id, id: this.$route.params.id + "?display=true"}).then((lesson)=> {
          this.item = lesson
          this.product = this.getProduct(this.purchase.product._id)
          if(!this.userInteracted) {
            this.isVideoPaused = true;
          }
          this.$forceUpdate();
          this.loading = false;
        }).catch(catcher);
      }).catch(catcher);
    }).catch(catcher);
  }
}
</script>
