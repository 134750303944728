<template>
  <v-container>
    <v-row v-if="item._id">
      <v-col cols="12" lg="6" xl="4">
        <v-img :aspect-ratio="16/9" :src="item.cover.url" style="border-radius: 24px"></v-img>
      </v-col>
      <v-col cols="12" lg="6" xl="8">
        <h2 class="no-select display-1 white--text fw-1 text-uppercase mb-4">{{item.title.it}}</h2>

        <v-row>
          <v-col cols="12">
            <v-divider dark class="mb-3"/>
          </v-col>
          <v-col :cols="isHomeKit ? 6 : 4" v-if="!item.category.is_gym_category">
            <p class="no-select subtitle-2 text-uppercase white--text">categoria</p>
            <p class="no-select title accent--text text-uppercase">{{item.category.name.it}}</p>
          </v-col>
          <v-col v-if="item.category.is_gym_category">
            <p class="no-select subtitle-2 text-uppercase white--text">categoria</p>
            <p class="no-select title accent--text text-uppercase">{{subcategory ? subcategory.name.it : ''}}</p>
          </v-col>
          <v-col v-if="!item.category.is_gym_category" :cols="isHomeKit ? 6 : 4">
            <p class="no-select subtitle-2 text-uppercase white--text">ideato per</p>
            <p class="no-select title accent--text text-uppercase">{{item.genre.name.it}}</p>
          </v-col>
          <v-col cols="4" v-if="!isHomeKit && !item.category.is_gym_category">
            <p class="no-select subtitle-2 text-uppercase white--text">difficoltà</p>
            <v-chip class="no-select d-inline-flex" :color="difficultyColor(item.difficulty)">{{item.difficulty.abbrev.it}}</v-chip>
          </v-col>
          <v-col cols="12">
            <v-divider  dark/>
          </v-col>
          <v-col cols="12">
            <p class="no-select subtitle-2 text-uppercase white--text">descrizione</p>
            <p class="no-select body-1 white--text">{{item.description.it}}</p>
          </v-col>

        </v-row>

      </v-col>
      <v-col cols="12">
        <v-divider  dark/>
      </v-col>
      <v-col cols="12" md="6" lg="8" xl="9" class="fill-height d-flex align-center">
        <h3 class="no-select headline white--text fw-1 text-uppercase mt-3">SELEZIONA IL PACCHETTO CHE VUOI ACQUISTARE</h3>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3" class="d-flex align-center">
        <v-select :items="productOptions" hide-details v-model="selectedProductId" rounded filled dark  />
      </v-col>
      <v-col cols="12">
        <v-divider  dark/>
      </v-col>
      <v-col cols="12" v-if="selectedProductId">
        <p class="no-select subtitle-2 text-uppercase white--text">descrizione del pacchetto</p>
        <p class="no-select body-1 white--text">{{selectedProduct.description.it}}</p>
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 d-flex justify-end" v-if="selectedProductId">
        <v-btn v-if="!selectedProduct.is_seats_product && isOwned(selectedProduct)" class="flex-shrink-0 flex-grow-1 flex-md-grow-0 flex-md-shrink-1" rounded color="accent" @click="$router.push('/mytrainings')" >È già tuo!</v-btn>
        <v-btn v-else class="flex-shrink-0 flex-grow-1 flex-md-grow-0 flex-md-shrink-1" rounded color="accent" @click="addProductToCart()" >Acquista!</v-btn>

      </v-col>
    </v-row>

    <v-dialog v-model="addedToCartDialog" persistent max-width="500" class="secondary" dark>
      <v-card>
        <v-card-title class="title fw-1 text-uppercase accent--text">AGGIUNTO AL CARRELLO!</v-card-title>
        <v-card-text>
          <p class="body-1">Hai inserito questo allenamento nel tuo carrello, vuoi finalizzare l'acquisto ed iniziare ad allenarti?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="$router.replace('/')">Cerca altro</v-btn>
          <v-btn color="accent" text @click="$router.replace('/cart')">Vai al carello</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'
import config from "../config";
import utils from "../utils";
export default {
  name: "Kit",
  data () {
    return {
      addedToCartDialog : false,
      item : {
        title : { it : '' },
        description : { it : '' },
        category : { },
        cover : '',
      },
      products : [],
      selectedProductId : null,
      subcategory : null
    }
  },
  computed : {
    ...mapState(['bgs']),
    ...mapGetters(['token', 'profile','bgImage','kits','purchases','cart']),
    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    isHomeKit() {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i].sku === config.homeKitSkuF || this.products[i].sku === config.homeKitSkuM) return true
      }
      return false;
    },
    productOptions() {
      return this.products.map((item)=>{
        let price = item.price;
        if(item.second_price && this.isPurchased(item)){
          price = item.second_price;
        }
        let text = `${item.title.it} `;
        if(this.isHomeKit) {
          const iva = price / 100 * config.iva
          price += iva;
        }
        if(this.isHomeKit) {
          text += `- ${price.toFixed(2)}€ iva inclusa`;
        } else if(item.is_seats_product) {
          text += `- ${price.toFixed(2)}€`;
        } else {
          text += `- a partire da ${price.toFixed(2)}€`;
        }
        return { text, value : item._id }
      })
    },
    selectedProduct() {
      if(!this.selectedProductId) return null
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === this.selectedProductId) {
          return this.products[i];
        }
      }
      return null
    },
    isInCart() {
      for(let i = 0; i < this.cart.items.length; i++) {
        if(this.cart.items[i] === this.selectedProductId) return true;
      }
      return false;
    },
  },
  methods : {
    ...mapActions(['load', 'get', 'sendError', 'refreshCollection', 'requestConfirm','notFound','addToCart','saveUserCart']),
    isOwned( product ) {
      for(let i = 0; i < this.purchases.length; i++) {
        const purchase = this.purchases[i];
        if(purchase.product._id === product._id && !purchase.consumed && !utils.isExpired(purchase.expiration)) return true;
      }
      return false;
    },
    isPurchased(product) {
      let filter = this.purchases.filter((item) => item.product._id === product._id)
      return filter.length > 0;
    },
    difficultyColor(difficulty) {
      switch (difficulty.value) {
        case 1:
          return 'success'
        case 2:
          return 'lime'
        case 3:
          return 'warning'
        case 4:
          return 'error'
      }
      return 'grey'
    },

    addProductToCart() {
      if(this.isInCart) {
        this.$router.push("/cart");
        return
      }
      this.addToCart({ product: _.cloneDeep(this.selectedProduct)});
      this.saveUserCart();
      this.addedToCartDialog = true;
    },
    loadkitProducts() {
      let filter = {
        query : { kit : this.$route.params.id },
        limit : 0, page : 1, sort : null, order : null
      };

      this.load({ collection : 'products', filter}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          if(this.$route.query.sub) {
            this.products = reply.data.filter(item => item.subcategory_id === this.$route.query.sub);
          } else {
            this.products = reply.data;
          }
          if(this.$route.query.p) {
            this.selectedProductId = this.$route.query.p;
          }
        }
      }).catch((err)=>{
        this.loading = false;
        this.notFound();
      })
    },
    async loadSubcategory() {
      if(!this.$route.query.sub) return;
      try {
        let reply = await this.get({ collection : 'subcategories', id: this.$route.query.sub +'?display=true'});
        if(!reply.data) {
          this.notFound();
          return
        }
        this.subcategory = reply.data;
      } catch (err) {
        this.loading = false;
        this.notFound();
      }

    },
    loadData() {
      this.get({ collection : 'kits', id: this.$route.params.id +'?display=true'}).then((reply)=>{
        if(!reply.data) {
          this.notFound();
          return
        }
        this.item = reply.data;
        this.loadkitProducts()
      }).catch((err) => {
        this.loading = false;
        this.notFound();
      })
    }
  },
  async mounted() {
    this.loading = true;
    this.loadData();
    await this.loadSubcategory();
  }
}
</script>
