<template>
    <v-container fluid class="fill-height" dark >
        <v-row>
            <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0">
                <v-row>
                    <v-col cols= 12 class="d-flex justify-center flex-grow-1 flex-shrink-0">
                        <v-img class="flex-grow-0" alt="Top Up Your Personal Gym logo" contain height="120" src="../assets/logo-esteso-alpha@2x.png" transition="scale-transition"></v-img>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <h2 class="display-1 white--text d-inline-flex align-center justify-center fw-1 no-select" >LA PAGINA CHE CERCHI NON ESISTE</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn class="flex-grow-0 flex-shrink-1" rounded color="accent" @click="$router.replace('/')">vai alla home</v-btn>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "NotFound",
        data() {
            return {
            };
        },
        computed: {
        },
        methods: {

        },
        mounted() {
        }
    };
</script>
